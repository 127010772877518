import React, { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';

import { Envelope, Telephone, GeoAlt } from 'react-bootstrap-icons';


// Code for /Contact page

export default function Careers () {
  // States
  const [positionFilter, setPositionFilter] = useState({
    id: 'all',
    text: 'All Positions'
  });
  const [employmentFilter, setEmploymentFilter] = useState({
    id: 'all',
    text: 'All Employment Types'
  });
  const [locationFilter, setLocationFilter] = useState({
    id: 'all',
    text: 'All Locations'
  });
  const [productFilter, setProductFilter] = useState({
    id: 'all',
    text: 'All Products'
  });

  const [jobListingsDict, setJobListingsDict] = useState({});


  // effects
  useEffect(() => {
    // get data from database,
    // and store it in "jobListingsDict" state
    const db = firebase.firestore();
    const listener = db.collection('jobListings').onSnapshot(snap => {
      const tempJobDict = {};
      snap.forEach(jobSnap => {
        const thisJobListing = jobSnap.data();

        // check if anything vital (that will cause an error) is missing or should be Hidden.
        // If it is, continue
        if (!thisJobListing.position || !thisJobListing.product || !thisJobListing.location ||
              !thisJobListing.employmentType || !thisJobListing.id || thisJobListing.hidden)
          return;

        console.log('after filter', thisJobListing);

        tempJobDict[thisJobListing.id] = thisJobListing;
      });
      setJobListingsDict(tempJobDict);
      console.log('job dict', tempJobDict);

    }, error => {
      console.log(error);
    });
  }, []);



  // create cards for job listings

  const jobListingsXml = [];

  for (const id in jobListingsDict) {
    const thisJob = jobListingsDict[id];

    // if this job is supposed to be hidden, don't show it
    if (thisJob.hidden)
      continue;

    // check if this job matches filters.
    // If so, add to jobListingsXml list to be displayed
    if (
      // if filer is set to "all",
      // or if the chosen filter matches one of the positions of this job
      // move on to check the other filters
      (positionFilter.id === 'all' || thisJob.position.includes(positionFilter.id)) &&
      (productFilter.id === 'all' || thisJob.product.includes(productFilter.id)) &&
      (locationFilter.id === 'all' || thisJob.location.includes(locationFilter.id)) &&
      (employmentFilter.id === 'all' || thisJob.employmentType.includes(employmentFilter.id))
    ) {
      jobListingsXml.push(
        <Col lg={4} md={6} style={{'display': 'flex'}}>
          <Card className="dropShadow center">
            <Card.Body className="cardBodyWithBottomButton">
              <Card.Title>{thisJob.title}</Card.Title>
              {thisJob.readablePosition}<br/>
              {thisJob.readableEmploymentType}<br/>
              {thisJob.readableLocation}<br/>
              {thisJob.readableProduct}<br/>
              <Link to={"/Careers/"+thisJob.id}>
                <a className="cardBottomButton center">Learn More</a>
              </Link>
            </Card.Body>
          </Card>
        </Col>);
    }
  }

  if (jobListingsXml.length === 0) {
    if (productFilter.id === 'all' && locationFilter.id === 'all' &&
          employmentFilter.id === 'all' && positionFilter.id === 'all') {
      // no filters selected, results are probably still loading,
      // or internet is unavailable
      jobListingsXml.push(
        <h2 className="center greyText" style={{fontWeight: 300}}>
          Loading...
        </h2>
      );
    }
    else {
      // at least one filter set, probably no jobs match filter
      jobListingsXml.push(
        <h2 className="center" style={{fontWeight: 300, textAlign: 'center'}}>
          Sorry, no jobs match your selected filters
        </h2>
      );
    }
  }




  // this is what will be displayed on this page
  return (
    <div className="careersPage">
      {/* First section - banner */}
      <Jumbotron className="darkBackground">
        <Container className="mainContainer">
          <h1 className="titleHeader dropShadow">
            Careers
          </h1>

          <h3 className="subHeader dropShadow veryLightGreyText"
              style={{fontWeight: 300}}>
              Don’t see what you’re looking for? Send us an email - we’re always adding new roles and opportunities
          </h3>
        </Container>
      </Jumbotron>

      <Container className="mainContainer" style={{marginTop: '100px', marginBottom: '100px'}}>
        {/* Second section - dropdown buttons */}
        <Dropdown>
          <Dropdown.Toggle variant="light" className="defaultDropdown dropShadow">
            {positionFilter.text}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setPositionFilter({
                                            id: 'all',
                                            text: 'All Positions'
                                          })}>
              All Positions
            </Dropdown.Item>

            <Dropdown.Item onClick={() => setPositionFilter({
                                            id: 'softwaredeveloper',
                                            text: 'Software Developer'
                                          })}>
              Software Developer
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setPositionFilter({
                                            id: 'seniorsoftwaredeveloper',
                                            text: 'Senior Software Developer'
                                          })}>
              Senior Software Developer
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="light" className="defaultDropdown dropShadow">
            {employmentFilter.text}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setEmploymentFilter({
                                            id: 'all',
                                            text: 'All Employment Types'
                                          })}>
              All Employment Types
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setEmploymentFilter({
                                            id: 'fulltime',
                                            text: 'Full Time'
                                          })}>
              Full Time
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setEmploymentFilter({
                                            id: 'parttime',
                                            text: 'Part Time'
                                          })}>
              Part Time
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="light" className="defaultDropdown dropShadow">
            {locationFilter.text}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setLocationFilter({
                                            id: 'all',
                                            text: 'All Locations'
                                          })}>
              All Locations
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setLocationFilter({
                                            id: 'newyorkny',
                                            text: 'New York, NY'
                                          })}>
              New York, NY
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setLocationFilter({
                                            id: 'uticany',
                                            text: 'Utica, NY'
                                          })}>
              Utica, NY
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle variant="light" className="defaultDropdown dropShadow">
            {productFilter.text}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'all',
                                            text: 'All Products'
                                          })}>
              All Products
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'duckcreek',
                                            text: 'Duck Creek'
                                          })}>
              Duck Creek
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'dellboomi',
                                            text: 'Dell Boomi'
                                          })}>
              Dell Boomi
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'systemsolutions',
                                            text: 'Business System Solutions'
                                          })}>
              Business System Solutions
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'apgiant',
                                            text: 'AP Giant'
                                          })}>
              AP Giant
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'proof',
                                            text: 'Proof'
                                          })}>
              Proof
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setProductFilter({
                                            id: 'elephant',
                                            text: 'Elephant'
                                          })}>
              Elephant
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <br/><br/><br/>


        {/* Third section - all job listings */}
        <Row>
          {jobListingsXml}
        </Row>
      </Container>
    </div>
  );
}
