import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";

import bikeImage from '../images/bike.jpg';
import bike2Image from '../images/bike2.jpg';
import carImage from '../images/car.jpg';
import elephant2Image from '../images/elephant2.jpg';
import redisLogo from '../images/RedisLogo.png';
import data3Image from '../images/data3.jpg';
import data4Image from '../images/data4.jpg';
import serverImage from '../images/server.jpg';
import server2Image from '../images/server2.jpg';
import server3Image from '../images/server3.jpg';
import DCXASimg from '../images/DCXAS.jpg';
import Arrow from '../images/Arrow.jpg';
import Sky from '../images/Sky.jpg';
import Crystal from '../images/Crystal.jpg';

import { ReactComponent as CheckSvg } from '../images/done-white-18dp.svg';
import { Check2, Dot } from 'react-bootstrap-icons';




// This is the DCXAS page

export default function DCXAS () {
  return (
    <div>
      {/* This is the first section with the DCXAS header */}
      <Jumbotron className="blueGradientBackground" style={{padding: '1px'}}>
        <Container className="mainContainer" style={{marginTop: '90px', marginBottom: '90px'}}>
          <h1 className="titleHeader dropShadow" style={{marginBottom: '25px'}}>
            DCXAS™ 
          </h1>

          <h4 className="dropShadow veryLightGreyText" style={{textAlign: 'center'}}>
            DCXAS™ is a highly customizable XML Augmentation Subsystem for On-Prem DuckCreek that integrates with the Duck Creek platform providing a shadow database for back-end data developers to have accelerated and simplified access to the XML-encoded information.  
          </h4>
        </Container>
      </Jumbotron>


      {/* This is the second section that contains the cards */}
      <Container className="mainContainer" style={{marginTop: '110px', marginBottom: '150px'}}>
        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* First Card: Comprehensive Caching */}
            <Card className="dropShadow wideCard">
              <Card.Img src={DCXASimg} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Fast</Card.Title>
                <Card.Text>
                  Rapid access to the continuously updated, XML-encoded policy information by querying the DCXAS-provided XML-typed and XML-indexed column directly, speeding up your XML-related operations up to 10x faster! 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Second Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={Arrow} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Precise</Card.Title>
                <Card.Text>
                  Real-time access to a fully customizable set of the most frequently requested data points, pre-extracted from the XML, post-processed as needed, and exposed as regular columns. Plus, seamless integration into your built-in programming routine.  
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Third Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={Sky} variant="top" className="cardImageCap" />
              <Card.Body className="">
                <Card.Title className="darkBlueText">
                  Efficient
                </Card.Title>
                <Card.Text>
                  Access to the historical record of all changes occurring within any individual policy transaction over time. That means being always on top of your information and analytics for underwriting, actuarial, marketing, etc. Typical loss throughout the Duck Creek process is expected. However, it is now monitored and organized to optimize all coding endeavors. 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Fourth Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={Crystal} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Comprehensive Outlook</Card.Title>
                <Card.Text>
                  Full picture of how various system actions, including those caused by the rewrites, out-of-sequence endorsements, referrals, rollbacks, and similar, affect existing records. DCXAS™ offers detailed tracking of deprecations, replacements, duplications, and deletions during operations.  
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      
  </Container>

      {/* Third Section: Key Features */}
      <Jumbotron className="lightGreyBackground" style={{padding: '1px'}}>
        <Container className="mainContainer headerChecklistContainer"
                      style={{marginTop: '150px', marginBottom: '150px'}}>
          <h3 className="titleHeader">
            Key Features:
          </h3>
          <h4 className="checklistEntry">
            <Check2 className="check" />
           Fast & simplified access for back-end developers to XML-encoded data 
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            A custom-built solution to accommodate the different data structures needs of every organization    
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Seamless integration with the DuckCreek platform for up to 10x faster performance   
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            No data loss. Real-time access to all historical records organized in an easily accessible way 
          </h4>
          <Row>
            <Col lg={3}/>
            <Col>
              <Link to="/contact">
                <Button className="defaultButton">Contact Us</Button>
              </Link>
            </Col>
            <Col lg={3}/>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
}
