import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../App.css';

import firebase from "../../helperComponents/FirebaseConfig";

import typewriter from '../../images/typewriter.jpg';
import linkedInLogo from '../../images/linkedInLogo.png';
import jarredHeadshot from '../../images/jarredHeadshot.png';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

// This is the /Leaders/JarredCornwell page

export default function BioJarred () {
  return (
    <div>
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Jarred Cornwell
        </h1>
        <h4 className="sectionOneLiner" style={{textAlign: 'center', marginBottom: '80px'}}>
          Chief Technology Officer
        </h4>
        <Image src={jarredHeadshot} id="jarredHeadshot" className="roundedImage center"/>
        <p>
          <strong>Jarred Cornwell</strong> is the Chief Technology Officer and one of the founders of IT Room Solutions. He was selected and approved by the leadership team for the role.
          <br/><br/>
          Cornwell has a significant background in enterprise architecture, managing IT teams, designing and running enterprises, and insurtech. He has built transnational teams based on corporate vision and strategy and has a history of delivering highly complex solutions in a timely manner. Cornwell’s leadership is built around trust and providing his teams with room for learning and growth. As a leader, Cornwell guides, solves, and teaches, while instilling confidence in his teammates.
          <br/><br/>
          Passionate about economic opportunity, Cornwell’s technology solutions have created billions in revenue for clients. He believes that inefficiency does more than slow down operations but puts a cap on business opportunities. In response, Cornwell serves as a genuine partner to clients by identifying optimization opportunities, creating better processes, and delivering thoughtfully designed business systems.
          <br/><br/>
          Cornwell also brings value to ITRS in his role as CTO by understanding the specifics of how businesses interface with tech. Rather than pushing a technology onto an industry, it is important to partner with businesses and to understand how solutions can truly impact the industry. For example, Cornwell leverages a deep knowledge of the insurance industry to provide unparalleled insurtech solutions.
        </p>
        <br/>
        <a href="https://www.linkedin.com/in/jarred-cornwell-901039b8">
          <Image src={linkedInLogo} className="linkedInLogo"/>
        </a>
      </Container>




    </div>
  );
}
