import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';

import { Envelope, Telephone, GeoAlt } from 'react-bootstrap-icons';

import CaptchaConfig from "../helperComponents/CaptchaConfig";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';


// Code for /Contact page



export default function Contact () {
  // Refs
  const captchaRef = useRef(null);
  const captchaCompleted = useRef(false);

  // states
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [subjectValue, setSubjectValue] = useState('');
  const [productValue, setProductValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [agreeValue, setAgreeValue] = useState(false);
  const [honeypotUsed, setHoneypotUsed] = useState(false);
  const [redirectState, setRedirectState] = useState({
      shouldRedirect: false,
      to: '',
    });

  // should we be showing an alert right now?
  const [alertState, setAlertState] = useState({
    visible: false,
    message: '',
    variant: 'danger',
  });


  // helper functions
  const setAlertTimeout = (delay = 2000) => {
    // start a countdown timer. after the timer expires, clear any visible alerts
    // delay is number of ms before alert is cleared
    setTimeout(() => {
      setAlertState({
        visible: false,
        message: '',
        variant: 'danger',
      });
    }, delay);

  }

  const validateAllForms = () => {
    // go through all input forms and make sure something valid has been entered for each
    // return true if all are valid, return false if at least one is invalid

    if (honeypotUsed) {
      // a bot triggered the honeypot, redirect to home page (away from field),
      // and don't submit data
      setRedirectState({
        shouldRedirect: true,
        to: '/'
      });

      setAlertState({
        visible: true,
        // this is untrue, message was not sent
        message: 'You message has been sent, thank you',
        variant: 'success',
      });

      setAlertTimeout();

      return false;
    }

    if (nameValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter your name',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (emailValue.length < 5 || !emailValue.includes('@') || !emailValue.includes('.')) {
      setAlertState({
        visible: true,
        message: 'Error: Invalid email address',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (subjectValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter a subject',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (productValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please choose a product or service',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (messageValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter a message',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (!agreeValue) {
      setAlertState({
        visible: true,
        message: 'Error: Please agree to allow this site to store your data',
        variant: 'danger',
      });

      setAlertTimeout(4000);
      return false;
    }
    if (!captchaCompleted.current) {
      setAlertState({
        visible: true,
        message: 'Error: Please complete captcha',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }



    // after captcha is validated once, reset captcha
    captchaRef.current.reset();

    return true;
  }

  const sendButtonClicked = () => {
    // this is what happens when someone clicks the "send" button
    const valid = validateAllForms();

    if (!valid)
      return;

    console.log('message is', messageValue);

    // current time in ms since unix epoch
    const currTime = Date.now();

    const db = firebase.firestore();
    const entriesRef = db.collection('contactEntries');
    // the id of this entry will be ce_ followed by the current time in ms since unix epoch
    entriesRef.doc('ce_'+currTime).set({
      id: 'ce_'+currTime,
      hidden: false,
      timeMs: currTime,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      name: nameValue,
      email: emailValue,
      subject: subjectValue,
      product: productValue,
      message: messageValue,
    }).then(() => {
      setAlertState({
        visible: true,
        message: 'You message has been sent, thank you',
        variant: 'success',
      });

      setAlertTimeout(3000);

      setNameValue('');
      setSubjectValue('');
      setEmailValue('');
      setProductValue('');
      setMessageValue('');
      setAgreeValue(false);


    }).catch((error) => {
      setAlertState({
        visible: true,
        message: 'Error: ' + error.message,
        variant: 'danger',
      });

      setAlertTimeout(3000);
    });

  }

  const onCaptchaChanged = (token) => {
    if (!token) {
      //console.log('null for captcha');
      captchaCompleted.current = false;
    }
    else {
      //console.log("Captcha sucess");
      // post to our server to verify captcha

      axios.post('https://us-central1-it-room-site.cloudfunctions.net/captcha',
          {'token': token})
        .then((res) => {
          const fromServer = res.data;

          if (fromServer['success']) {
            // captcha verified successfully
            captchaCompleted.current = true;
            console.log('captcha verified successfully');
          }
          else {
            // captcha not verified
            captchaCompleted.current = false;
            captchaRef.current.reset();

            setAlertState({
              visible: true,
              message: 'Captcha Failed',
              variant: 'danger',
            });

            setAlertTimeout();
            console.log('captcha not verified', fromServer);
          }
        });
    }
  }



  // Generate Xml
  const generateAlert = () => {
    // check alertState and if necessary, build an alert based on it
    // if alertState.visible is not true, add 'opacity0' class
    return <Alert variant={alertState.variant}
                className={!alertState.visible? 'opacity0 displayNone': ''}>
      {alertState.message}
    </Alert>;
  }

  const currentAlert = generateAlert();



  if (redirectState.shouldRedirect)
    return <Redirect to={redirectState.to} />;


  // this is what will be displayed on this page
  return (
    <div>
      {/* First section - banner */}
      <Jumbotron className="darkBackground">
        <Container className="mainContainer">
          <h1 className="titleHeader dropShadow">
            Contact Us
          </h1>
          <h3 className="subHeader dropShadow veryLightGreyText"
              style={{fontWeight: 300}}>
            Speak directly to one of our engineers about your enterprise needs
          </h3>
        </Container>
      </Jumbotron>

      {/* Second section - input forms */}
      <Container className="mainContainer">
        <Row>
          <Col lg={2}></Col>
          <Col>
            <h5 className="darkBlueText formLabel">Name</h5>
            <Form.Control className="formInput"
                      placeholder="Enter Your Name"
                      value={nameValue}
                        // when a user types in this field,
                        // save what they type in the 'nameValue' state
                      onChange={(event) => {setNameValue(event.target.value)}}/>

            <h5 className="darkBlueText formLabel">Email</h5>
            <Form.Control className="formInput" type="email"
                      placeholder="Enter Your Email"
                      value={emailValue}
                      onChange={(event) => {setEmailValue(event.target.value)}}/>

            <h5 className="darkBlueText formLabel">Subject</h5>
            <Form.Control className="formInput"
                      placeholder="Enter A Subject"
                      value={subjectValue}
                      onChange={(event) => {setSubjectValue(event.target.value)}}/>

            <h5 className="darkBlueText formLabel">Product/Service</h5>
            <Form.Control as="select" className="formInput"
                        value={productValue}
                        onChange={(event) => {setProductValue(event.target.value)}}>
              <option default style={{display: 'none'}}>Choose one of our Products and Services</option>
              <option value="duckcreek">Duck Creek Solutions</option>
              <option value="dellboomi">Dell Boomi Services</option>
              <option value="systemsolutions">Business System Solutions</option>
              <option value="apgiant">AP Giant</option>
              <option value="proof">Proof</option>
              <option value="elephant">Elephant</option>
              <option value="other">Other</option>
            </Form.Control>

            <h5 className="darkBlueText formLabel">Message</h5>
            <Form.Control as="textarea" rows={10} className="formInput"
                        placeholder="Write A Message..."
                        value={messageValue}
                        onChange={(event) => {setMessageValue(event.target.value)}}/>

            {/* Honeypot */}
            <Form.Control type="text" name="enter_password" style={{display:'none'}}
                tabindex="-1" autocomplete="off" onChange={() => setHoneypotUsed(true)} />

            <input id="allowDataCheck" className="form-check-input"
                    type="checkbox" checked={agreeValue}
                    onChange={(event) => setAgreeValue(event.target.checked)}/>
            <span className="form-check-label" onClick={setAgreeValue.bind(this, !agreeValue)}>
              I agree to allow this website to storage and handle my data
            </span>
            <div style={{height: '20px'}}/>
            <ReCAPTCHA className="captcha"
              ref={captchaRef}
              sitekey={CaptchaConfig.siteKey}
              onChange={onCaptchaChanged}
            />
            <Button className="defaultButton" onClick={sendButtonClicked}>Send</Button>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </Container>

      {/* Third section - other ways to reach us */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer">
          <Row>
            <Col lg={3}></Col>
            <Col>
              <h4 className="darkBlueText formLabel" style={{textAlign: 'center'}}>
                Other Ways to Reach Us
              </h4>
              <p style={{padding: '10px 10px', lineHeight: '3.5rem'}}>
                <a href="tel:+1-929-267-3063" className="otherContactLink">
                  <Telephone className="otherContactIcon"/>+1 929 267 3063
                </a>
                <br/>
                <a href="mailto:contact@itroomsolutions.com" className="otherContactLink">
                  <Envelope className="otherContactIcon"/>
                  <span style={{wordBreak: 'break-all'}}>contact@itroomsolutions.com</span>
                </a>
                <br/>
                <a href="https://maps.google.com/maps/search/?api=1&query=258+Genesee+St+%23510,+Utica,+NY+13502"
                    className="otherContactLink">
                  <GeoAlt className="otherContactIcon"/>258 Genesee St, Suite 510, Utica NY, 13502
                </a>
              </p>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </Jumbotron>

      {currentAlert}
    </div>
  );
}
