import React, { useState, useEffect } from 'react';

import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image'

import ImageUploading from 'react-images-uploading';

import './App.css';

import firebase from './helperComponents/FirebaseConfig';

import Home from './pages/Home';

import DuckCreek from './pages/DuckCreek';
import DellBoomi from './pages/DellBoomi';
import BusinessSystemSolutions from './pages/BusinessSystemSolutions';
import APGiant from './pages/APGiant';
import Proof from './pages/Proof';
import Elephant from './pages/Elephant';
import DCXAS from './pages/DCXAS';

import About from './pages/About';
import Resources from './pages/Resources';
import Careers from './pages/Careers';
import JobListing from './pages/JobListing';
import Contact from './pages/Contact';
import CaseStudies from './pages/CaseStudies';

import Admin from './pages/Admin';

import BioJarred from './pages/bios/BioJarred';
import BioSchuyler from './pages/bios/BioSchuyler';
import BioJeffrey from './pages/bios/BioJeffrey';
import BioJustin from './pages/bios/BioJustin';

import ResourcesPurposefulTech from './pages/resourcesPages/ResourcesPurposefulTech';
import ResourcesTuckerAndDerek from './pages/resourcesPages/ResourcesTuckerAndDerek';

import logo from './images/logo.png';

// This site has pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

// this is the overall structure of the site
// urls -> functions

function NavStructure() {
  // States
  const [signedInUser, setSignedInUser] = useState(null);
  const [navExpanded, setNavExpanded] = useState(false);

  // effects
  // check for a signed in user
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var displayName = user.displayName;
        var email = user.email;
        var emailVerified = user.emailVerified;
        var photoURL = user.photoURL;
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        var providerData = user.providerData;
        // ...
        setSignedInUser(user);
      } else {
        // User is signed out.
        // ...
        setSignedInUser(null);
      }
    });
  }, []);

  const location = useLocation();
  useEffect(() => {
    // scroll to top and close nav bar when page changes
    window.scrollTo(0, 0);
    setNavExpanded(false);
  }, [location]);

  const contentsOfDropdown = <>
    <NavDropdown.Header >Consulting Services</NavDropdown.Header >
    <Link to="/Solutions">
      <NavDropdown.ItemText>Enterprise Solutions</NavDropdown.ItemText>
    </Link>
    <Link to="/DuckCreek">
      <NavDropdown.ItemText>Duck Creek Solutions</NavDropdown.ItemText>
    </Link>
    <Link to="/DellBoomi">
      <NavDropdown.ItemText>Dell Boomi Services</NavDropdown.ItemText>
    </Link>


    <NavDropdown.Divider />

    <NavDropdown.Header >Products</NavDropdown.Header>
    <Link to="/APGiant">
      <NavDropdown.ItemText>AP Giant</NavDropdown.ItemText>
    </Link>
    <Link to="/Proof">
      <NavDropdown.ItemText>Proof</NavDropdown.ItemText>
    </Link>
    <Link to="/Elephant">
      <NavDropdown.ItemText>Elephant</NavDropdown.ItemText>
    </Link>
	<Link to="/DCXAS">
      <NavDropdown.ItemText>DCXAS</NavDropdown.ItemText>
    </Link>
  </>;

  return (
      <div>
        {/* This is the header/navigation bar */}
        <Navbar bg="white" expand="lg" onToggle={setNavExpanded}
                                        expanded={navExpanded}>
          <Link to="/">
            <Image id="logoImage" src={logo}/>
            <Navbar.Brand>IT Room Solutions</Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {/* use dropdown only on large screens */}
              <NavDropdown title="Technology Solutions" id="basic-nav-dropdown"
                    className="d-none d-lg-block">
                {contentsOfDropdown}
              </NavDropdown>

              {/* for small screens, don't use dropdown, only show links
                  this will only be visible on small screens */}
              <div className="d-block d-lg-none"
                  style={{marginBottom: '20px', marginTop: '20px'}}>
                <Navbar.Text style={{fontSize: '1rem'}}>
                  Technology Solutions
                </Navbar.Text>
                {contentsOfDropdown}
              </div>
              <Link to="/About">
                <Navbar.Text className="NavLink">Why ITRS?</Navbar.Text>
              </Link>
              <Link to="/Resources">
                <Navbar.Text className="NavLink">Resources</Navbar.Text>
              </Link>
              <Link to="/Careers">
                <Navbar.Text className="NavLink">Careers</Navbar.Text>
              </Link>
              <Link to="/Contact">
                <Navbar.Text className="NavLink">Contact Us</Navbar.Text>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time

          // This site has pages, all of which are rendered
          // dynamically in the browser (not server rendered).
          //
          // Although the page does not ever refresh, notice how
          // React Router keeps the URL up to date as you navigate
          // through the site. This preserves the browser history,
          // making sure things like the back button and bookmarks
          // work properly.

          // this is the overall structure of the site
          // urls -> functions
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/duckcreek">
            <DuckCreek />
          </Route>
          <Route path="/dellboomi">
            <DellBoomi />
          </Route>
          <Route path="/solutions">
            <BusinessSystemSolutions />
          </Route>
          <Route path="/apgiant">
            <APGiant />
          </Route>
          <Route path="/proof">
            <Proof />
          </Route>
          <Route path="/elephant">
            <Elephant />
          </Route>
		   <Route path="/dcxas">
            <DCXAS />
          </Route>

          <Route path="/about">
            <About />
          </Route>

          { // The order is important here. React matches from the top down,
            // so if /resources comes first, it will return /resources even for
            // a request to /resources/MeetTuckerAndDerek
          }
          <Route path="/resources/meettuckerandderek">
            <ResourcesTuckerAndDerek />
          </Route>
          {/*}<Route path="/resources/whatispurposefultechnology">
            <ResourcesPurposefulTech />
          </Route>*/}
          <Route path="/resources">
            <Resources />
          </Route>

          <Route path="/careers/:id">
            <JobListing />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/contactus">
            <Contact />
          </Route>
          <Route path="/casestudies">
            <CaseStudies />
          </Route>


          <Route path="/leaders/jarredcornwell">
            <BioJarred />
          </Route>
          <Route path="/leaders/schuylercornwell">
            <BioSchuyler />
          </Route>
          <Route path="/leaders/jeffreyleonard">
            <BioJeffrey />
          </Route>
          <Route path="/leaders/justinyandell">
            <BioJustin />
          </Route>

          <Route path="/itrsadmin">
            <Admin />
          </Route>

          {/* Default for if url does not match any other routes */}
          <Route path="/">
            <div style={{height: '70px'}}/>
            <h1 className="titleHeader">
              404
              <br/>
              Page not found
            </h1>
            <Link to="/">
              <Button className="defaultButton center">Click here to go home</Button>
            </Link>
          </Route>
        </Switch>
      </div>
  );
}



export default function App () {
  return (
    <Router>
      <NavStructure />
    </Router>
  );
}
