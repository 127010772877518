import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";

import graphIcon from '../images/graphIcon.png';
import futureIcon from '../images/futureIcon.png';
import authorityIcon from '../images/authorityIcon.png';
import wrenchIcon from '../images/wrenchIcon.png';
import replaceIcon from '../images/replaceIcon.png';

import duckCreekSquareLogo from '../images/duckCreekSquareLogo.png';
import graphImage from '../images/graphImage.jpg';
import typewriter from '../images/typewriter.jpg';
import mechanic from '../images/mechanic.jpg';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';


// These are the breakpoints for the carousel.
// The number of visible items depends on the width of the window/viewport
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 577, min: 0 },
    items: 1
  }
};

// This is the /DuckCreek page

export default function DuckCreek () {

  const duckCreekAuthorityAnchor = useRef(null);
  const techStrategyAnchor = useRef(null);
  const replacyLegacyAnchor = useRef(null);
  const futureReadyAnchor = useRef(null);
  const maintenanceAnchor = useRef(null);

  const CarouselButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
     // These are the left and right arrows for the carousel
    return (
      <div className="carousel-button-group">
        <ChevronLeft id="carouselLeftArrow"  onClick={() => previous()} />
        <ChevronRight id="carouselRightArrow"  onClick={() => next()} />
      </div>
    );
  };

  return (
    <div>
      {/* This is the carousel at the top of the page */}
      <Jumbotron className="blueGradientBackground">
        <Container className="mainContainer">
          <h1 className="titleHeader dropShadow">
            Duck Creek Solutions
          </h1>
          <Carousel responsive={responsive} className="carousel"
                  arrows={false} renderButtonGroupOutside={true}
                  customButtonGroup={<CarouselButtonGroup />}
                  infinite={true}>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={authorityIcon} style={{'margin': '-20px 0',
                    'max-height': '110px'}}/>
                <Card.Title>Full-Service Duck Creek Development</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We deliver end-to-end Duck Creek implementations to transform and manage your P&C insurance business
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => duckCreekAuthorityAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={graphIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Insurtech Strategy</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We can help you develop business approaches that lower costs, maximize your ROI, and drive profit over the long haul
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => techStrategyAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={futureIcon} style={{'margin': '-15px 0',
                    'max-height': '100px'}}/>
                <Card.Title>Future-Ready</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  Our implementations are built on future-proof architecture
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => futureReadyAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={wrenchIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Maintenance and Support</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We provide ongoing Duck Creek maintenance, support and upgrades
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => maintenanceAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>
          </Carousel>
          <Link to="/contact">
            <Button className="defaultButton whiteButton dropShadow center">Contact Us</Button>
          </Link>
        </Container>
      </Jumbotron>

      {/* This is an anchor that the page will scroll to when "learn more" is clicked */}
      <div ref={duckCreekAuthorityAnchor} />

      {/* This is the first section of the page under the carousel: Full-service Duck Creek Development */}
      {/* the image is floated to the right so the section is offset to the left */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <Image src={duckCreekSquareLogo} className="float-md-right"/>
        <h4 className="sectionHeader">
          Full-Service Duck Creek Development
        </h4>
        <h5 className="sectionOneLiner">
          We deliver end-to-end Duck Creek implementations to transform and manage your P&C insurance business
        </h5>
        <p>
          ITRS is a full-service Duck Creek insurtech solutions provider. We supply technology strategy and end-to-end Duck Creek implementations that transform your P&C insurance business to meet the demands of today’s digital age. We have extensive experience managing Duck Creek policy administration systems with deep expertise in Rating, Billing, and Policy products.
          <br/><br/>
          Our Duck Creek solutions are backed by some of the most talented developers and architects in the industry today. They are supported at ITRS by a full staff of designers, project managers, quality assurance, and business analysts. As a team, ITRS draws on more than 75 years of collective experience providing state-of-the-art SaaS solutions for the P&C insurance industry.
        </p>
      </Container>

      {/* This is another anchor */}
      <div ref={techStrategyAnchor} />

      {/* This is the second section: Insurtech Strategy */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          <Image src={graphImage} className="float-md-left roundedImage"
              style={{'max-width': '360px'}} />
          <h4 className="sectionHeader">
            Insurtech Strategy
          </h4>
          <h5 className="sectionOneLiner">
            We can help you develop business approaches that lower costs, maximize your ROI, and drive profit over the long haul
          </h5>
          <p>
            Insurtech strategy is a key component of our Duck Creek services. By leveraging Duck Creek, we can help you develop business approaches that lower costs, maximize your ROI, and drive profit over the long haul.
            <br/><br/>
            In addition to off-the-shelf features, we use our deep insurance industry know-how and IT background to optimize Duck Creek functionality. In fact, our custom enhancements have been adopted in the official Duck Creek codebase to make the platform more accessible, scalable, and user-friendly.
            <br/><br/>
            Creating custom products allows us to manipulate Duck Creek in unexpected ways. This leads to reduced costs, new market opportunities, and the ability to build new business lines. Altogether, we use these abilities to provide businesses with strategies and processes that drive growth and profit.
          </p>
        </Container>
      </Jumbotron>

      <div ref={replacyLegacyAnchor} />
      <div ref={futureReadyAnchor} />
      {/* Third Section */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <Image src={typewriter} className="float-md-right roundedImage"
                                style={{'max-width': '400px'}}/>
        <h4 className="sectionHeader">
          Future-Ready
        </h4>
        <h5 className="sectionOneLiner">
          Our implementations are built on future-proof architecture
        </h5>
        <p>
          As a full-service Duck Creek authority, ITRS ensures that your business remains future-ready. One of the primary value propositions of Duck Creek is that it abstracts your business rules away from the core platform code. As a result, upgrades typically occur much quicker than other core systems.
          <br/><br/>
          Beyond Duck Creek upgrades, our implementations are built on future-proof architecture. This means that as clients continue to make changes, upgrades are completely seamless, and all of our development remains fully operational.
        </p>
      </Container>

      <div ref={maintenanceAnchor} />
      {/* Fourth Section */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          <Image src={mechanic} className="float-md-left roundedImage"
              style={{'max-width': '360px'}} />
          <h4 className="sectionHeader">
            Maintenance and Support
          </h4>
          <h5 className="sectionOneLiner">
            We provide ongoing Duck Creek maintenance, support and upgrades
          </h5>
          <p>
            ITRS provides world-class Duck Creek project execution, product development, maintenance, and support as well as upgrades to the platform. Altogether, these measures help to maximize your ROI by driving profit and reducing overall costs.
            <br/><br/>
            We begin with a prioritized list of the services upgrades that are most important to your business. Then we execute those changes until we complete your upgrade roadmap. We have a full team to oversee the entire project—from specs gathering to ensuring the success of test-driven development with separate QA and UAT after the fact.
            <br/><br/>
            We also build new business lines, build pages, build ratings for existing and new lines of business, and refactor Duck Creek code in order to improve known performance issues.
            <br/><br/>
            Many P&C insurers have decades of historical customer data. It may be tempting to delay an upgrade and keep piling more data on top of existing systems. But integrating legacy system data and settings is crucial when upgrading to Duck Creek OnDemand. Regardless of your current platform or environment, we can help you  transition to the cloud.
          </p>
        </Container>
      </Jumbotron>
    </div>
  );
}
