import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";

import solutionIcon from '../images/solutionIcon.png';
import communityIcon from '../images/communityIcon.png';
import futureGearIcon from '../images/futureGearIcon.png';
import reliableIcon from '../images/reliableIcon.png';
import communicationIcon from '../images/communicationIcon.png';

import roadImage from '../images/road.jpg';
import f1Image from '../images/f1.jpg';
import collabImage from '../images/collab.jpg';


import { ChevronRight, ChevronLeft, Dot } from 'react-bootstrap-icons';



// These are the breakpoints for the carousel.
// The number of visible items depends on the width of the window/viewport
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 577, min: 0 },
    items: 1
  }
};


// This is the /Solutions page

export default function BusinessSystemSolutions () {

  const longHaulAnchor = useRef(null);
  const teamBuiltAnchor = useRef(null);
  const efficiencyAnchor = useRef(null);
  const enterpriseSolutionsAnchor = useRef(null);
  const transparentProcessAnchor = useRef(null);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
     // These are the left and right arrows for the carousel
    return (
      <div className="carousel-button-group">
        <ChevronLeft id="carouselLeftArrow"  onClick={() => previous()} />
        <ChevronRight id="carouselRightArrow"  onClick={() => next()} />
      </div>
    );
  };

  return (
    <div>
      {/* This is the carousel at the top of the page */}
      <Jumbotron className="blueGradientBackground">
        <Container className="mainContainer">
          <h1 className="titleHeader dropShadow">
            Enterprise Solutions
          </h1>
          <Carousel responsive={responsive} className="carousel"
                  arrows={false} renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                  infinite={true}>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={solutionIcon} style={{'margin': '-20px 0',
                    'max-height': '110px'}}/>
                <Card.Title>User-centric Enterprise Solutions</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  Our enterprise IT solutions begin with you
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => enterpriseSolutionsAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={reliableIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Software for the Long Haul</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  Our team is trained to anticipate compatibility issues and future-proofing solutions to prevent bugs and slowdowns moving forward
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => longHaulAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={futureGearIcon} style={{'margin': '-15px 0',
                    'max-height': '100px'}}/>
                <Card.Title>Battle-tested Efficiency</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We believe in fast and efficient processes with dependable outcomes and measurable performance
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => efficiencyAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={communityIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Team-Built Software</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  Our software solutions are team-built for unparalleled quality and durability
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => teamBuiltAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={communicationIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Transparent Process</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We create a collaborative work process
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => transparentProcessAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>
          </Carousel>
          <Link to="/contact">
            <Button className="defaultButton whiteButton dropShadow center">Contact Us</Button>
          </Link>
        </Container>
      </Jumbotron>

      {/* This is an anchor that the page will scroll to when "learn more" is clicked */}
      <div ref={enterpriseSolutionsAnchor} />

      {/* This is the first section of the page under the carousel: User-centric Enterprise Solutions */}
      {/* the image is floated to the right so the section is offset to the left */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <h1 className="titleHeader">
          How We Build
        </h1>
        <div style={{height: '50px'}}/>
        <h4 className="sectionHeader">
          User-centric Enterprise Solutions
        </h4>
        <h5 className="sectionOneLiner">
          Our enterprise IT solutions begin with you
        </h5>
        <p>
          ITRS specializes in creating powerful, user-centric enterprise applications in the areas of insurtech, fintech, and business services. We know that your next challenge is likely to be as unique as your business itself.
          <br/><br/>
          We build custom products from scratch that solve your unique business challenges. What tool or service can we create to address your challenge and optimize your business moving forward? We start by assessing the specific pain point you’re trying to solve.
          <br/><br/>
          From there, we create a full project scope with a set of deliverables along with a roadmap with milestones for our engagement. We know that your needs may shift throughout the course. We also may discover insights along the way. In those moments, our transparent work philosophy, you get full visibility and partnership into the process as we move to completion.
        </p>
      </Container>

      {/* This is another anchor */}
      <div ref={longHaulAnchor} />

      {/* This is the second section:  Software for the Long Haul */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          {/* TODO: use react-lazy-progressive-image to optimize image loading */}
          <Image src={roadImage} className="float-md-left roundedImage"
                style={{height: '270px'}}/>
          <h4 className="sectionHeader">
            Software for the Long Haul
          </h4>
          <h5 className="sectionOneLiner">
            Our team is trained to anticipate compatibility issues and future-proofing solutions to prevent bugs and slowdowns moving forward
          </h5>
          <p>
            Have you ever dealt with an app that seems to get more and more dysfunctional over time? Maybe it’s a business integration tool that worked well when you first commissioned itbutover time you notice more bugs than benefits. Such software was likely developed with the short-term in mind and with little focus on the long haul.
            <br/><br/>
            ITRS develops IT solutions for longevity. We create apps and services that not only deliver immediately on your pain points but continue to offer powerful ROI rewards for your business into the future. Our team is trained to anticipate compatibility issues and future-proofing solutions to prevent bugs and slowdowns moving forward. ITRS delivers stress-tested software built with rigor and dependability in mind.
          </p>
        </Container>
      </Jumbotron>


      <div ref={efficiencyAnchor} />

      {/* Third Section */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <Image src={f1Image} className="float-md-right roundedImage"
                style={{height: '270px'}}/>
        <h4 className="sectionHeader">
          Battle-tested Efficiency
        </h4>
        <h5 className="sectionOneLiner">
          We believe in fast and efficient processes with dependable outcomes and measurable performance
        </h5>
        <p>
          Efficiency and productivity are priorities at ITRS. Rather than cutting corners and quick fixes, we believe in purposeful technology built by dependable, battle-tested professionals. Offering a premium service, we leverage our extensive expertise to deliver higher quality outcomes in a fraction of the time. For our clients, this means more predictable development schedules and lower costs of ongoing maintenance.
          <br/><br/>
          Our business integrity and tech authority result in a strategic advantage for creating IT solutions that drive your business. Other IT consulting firms may appear to work quickly. But upon inspection, their deliverables come with bugs or issues that drag down your productivity and, ultimately, strain your profits. We believe in fast and efficient processes with dependable outcomes and measurable performance.
        </p>
      </Container>


      <div ref={teamBuiltAnchor} />

      {/* Fourth Section */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          <h1 className="titleHeader">
            What We Build
          </h1>
          <div style={{height: '50px'}}/>
          <h4 className="sectionHeader">
            Team-Built Software
          </h4>
          <h5 className="sectionOneLiner">
            Our software solutions are team-built for unparalleled quality and durability
          </h5>
          <p>
            Client-centered teams come first at ITRS. We create managed IT solutions through high-powered, full-visibility processes. We do this by leveraging exceptional engineers in teams that drive solutions. Rather than churn through questionable or high-risk contractors, we prioritize deep integration and retention factors to create teams of superlative collaborators eager to meet your software needs.
            <br/><br/>
            Our software solutions are team-built for unparalleled quality and durability. As opposed to quickly assembled ad-hoc groups with lots of unknowns, our teams consist of road-tested IT consultants. These trusted developers bring invaluable experience to the table and work together in lock-step. The result is exceptional software driven by a transparent process with your full visibility.
          </p>
        </Container>
      </Jumbotron>


      <div ref={transparentProcessAnchor} />

      {/* Fifth Section */}
      <Container className="mainContainer offsetSection offsetRightSection">
        <Image src={collabImage} className="float-md-left roundedImage"/>
        <h4 className="sectionHeader">
          Transparent Process
        </h4>
        <h5 className="sectionOneLiner">
          We create a collaborative work process
        </h5>
        <p>
          We believe that success begins with transparency. Our IT consulting services are more efficient and dependable because we are open about the challenges we face. We create a collaborative work process and share consistent updates throughout the project life cycle to keep you informed every step of the way.
        </p>
      </Container>
    </div>
  );
}
