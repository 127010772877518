import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../App.css';

import firebase from "../../helperComponents/FirebaseConfig";

import typewriter from '../../images/typewriter.jpg';
import linkedInLogo from '../../images/linkedInLogo.png';
import tuckerAndDerekHeadshot from '../../images/tuckerAndDerekHeadshot.png';
import solution2 from '../../images/solution2.png';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';




export default function ResourcesTuckerAndDerek () {
  return (
    <div>
      <Container className="mainContainer resourcesPost">
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '60px' }}>
          Getting Started with Dell Boomi
        </h1>
        <Image src={tuckerAndDerekHeadshot} className="roundedImage w-100" style={{marginBottom: '80px'}}/>

        <h5>Meet Tucker & Derek</h5>
        <p>
          We’re Derek Cory and Tucker Mogren and we're certified Boomi Professional Integration Developers at IT Room Solutions. We’ll be publishing regular Dell Boomi tips and tutorials to help other IT professionals master the platform and get the most out of it for their clients.
        </p>

        <h5>Dell Boomi Training: The Exams</h5>
        <p>
          We both got started with Dell Boomi but taking the free training courses available, followed by completing some of the available certifications including Boomi Flow Associate Developer, Boomi Associate Developer and Boomi Professional Developer.
        </p>

        <h5>What To Expect</h5>
        <p>
          The format of the exams was relatively similar for all of them. Each training course provided us with in-depth training videos and practice exercises to reinforce the concepts that were being taught within the videos. Following the completion of the videos and training exercises an exam was unlocked which required an 80% on each section to pass. In the event you fail, there is a three-day cool down period for you to review the material and retake the exam, and you have a maximum of 3 attempts to pass. All the exams are open notes, which helps greatly as the material is lengthy and the questions are specific.
          <br/><br/>
          The training length varied greatly between the courses. The Flow Associate developer took 3 hours of online lectures and then probably another hour for the exam (note: the exams themselves are not timed, so you can take as much time as you need). The Associate developer training took 10 hours and the exam took 4 hours. The Professional developer training was the hardest of the bunch with the training itself only taking 4 hours but the exam taking us roughly 10 hours. Exam times could vary greatly depending on how readily available the information is to you and if you break up the different parts of the exam.
        </p>

        <h5>Tips For The Exams</h5>
        <p>
          Just because the tests are open notes, does not make them easy. Here are some things we recommend to make the tests easier.
          <br/><br/>
          1. Keep a copy of the slides close by for quick reference during the exam.
          <br/>
          2. Do not be afraid to look back at your completed integration activities to validate your answers.
          <br/>
          3. Keep a set of your own notes to write down specifics that are mentioned in the lectures but not written down in the activity guide or the slides.
          <br/>
          4. Lastly, take your time, validate your answers, the test is not timed so there is no rush to completing it.
        </p>

        <h5>Putting that Training to Work</h5>
        <p>
          After successfully completing the training, we started to lay the groundwork for our upcoming integration. This integration would connect two different systems which would reduce data input redundancy and minimize the risk of incorrect data input.
          <br/><br/>
          As we were building, we came across a few challenges we had to troubleshoot. Here are some of our challenges and resolutions to make your first integration a little bit easier.
        </p>

        <h5>Challenge #1: HTTP Connector Troubleshooting</h5>
        <p style={{marginBottom: '30px'}}>
          One of the initial problems that we came across dealt with the HTTP connector. A connector basically allows Boomi to connect to another Web Service or System. Each connector has different configurations that enable developers to customize each connector as they see fit to ensure that a connection between the services is successful. Initially, parameters were set to attempt to connect to one of the services. However, upon attempting to send a request through the connector, a blank payload is sent. This ended up being due to the parameters that were being set.
        </p>
        <h5>Solution:</h5>
        <p>
          To resolve this, (1) remove parameters and (2) send the JSON request via a message shape instead of a connector.
        </p>

        <h5>Challenge #2: Structuring the Boomi Environment</h5>
        <p style={{marginBottom: '30px'}}>
          When there are multiple developers working in the Boomi environment, folder structure can get messy very quickly when a standard isn't set and followed along the way. Boomi provides some basic standards such as keeping all the connections in a #Connections folder in the root so that they are easily accessible, but otherwise it is mostly up to the developers to keep the environment organized and clean. To ensure that the environment is easily readable by anyone, components are named according to their function (I.e. ABC to DEF map).
        </p>
        <h5>Solution:</h5>
        <Image src={solution2} className="center" style={{marginBottom: '80px'}}/>
        <p>
          The top two folders, #Connections and #Operations are used to promote reusability of connections and operations. Reusing connections is especially important because if you create duplicate connections it counts against your license count. Eliminating any duplicate connections is a good Boomi practice. You could also create a #Profiles folder if you have repeated profiles, it all depends on your methodology.
        </p>

        <h5>Challenge #3: Error Handling</h5>
        <p style={{marginBottom: '30px'}}>
          Error handling in Boomi is another area that can cause some confusion for new developers. Try/Catch blocks in Boomi differ from other development platforms in the sense that Try/Catch blocks for Boomi span the life of the entire process, vs just a specific “block”. We will be the first to admit that we have not completely figured out the best way to handle errors within Boomi, but here are a few tips/tricks that we gathered over the last 6 months.
        </p>
        <h5>Solution:</h5>
        <p>
          When considering error handling for your process, don’t just consider a try/catch shape. Decision shapes, route shapes, and business rules can be used alongside either stop or exception shapes to add a more granular level of error handling/checking to your process. You can also use notify shapes to save error messages to the logs of your process to get a better idea of what might be going wrong in your process. Say you need to make sure a value is above a certain threshold, you could use a decision shape to check the value and if it's false, send it to a notify shape and an exception shape. This is good because you will have a log from the error, and the process will stop executing, or instead of doing an exception shape you could actually handle said error and re-route the process so it continues to execute with the handled value. What you choose will depend mostly on how your process needs to perform when encountering errors.
          <br/><br/>
          So ultimately, there are two different ways you could display an error message, through an email or via the execution logs. Which route you choose to go depends on your use case. An email will give you an immediate notification of an error where as a log would help you further investigate the issue.
        </p>

        <p style={{marginBottom: '30px'}}>
          Thanks for reading and we hope this helps you kick off your own Dell Boomi career!
        </p>
        <Link to="/Resources">
          <p style={{marginBottom: '30px'}}>
            Visit our resources page for more Dell Boomi tips.
          </p>
        </Link>




      </Container>




    </div>
  );
}
