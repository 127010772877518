import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../App.css';

import firebase from "../../helperComponents/FirebaseConfig";

import typewriter from '../../images/typewriter.jpg';
import linkedInLogo from '../../images/linkedInLogo.png';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

import jeffreyHeadshot from '../../images/jeffreyHeadshot.png';


// This is the /Leaders/JeffreyLeonard page


export default function BioJeffrey () {
  return (
    <div>
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Jeffrey Leonard
        </h1>
        <h4 className="sectionOneLiner" style={{textAlign: 'center', marginBottom: '80px'}}>
          Chief Financial Officer
        </h4>
        <Image src={jeffreyHeadshot} id="schuylerHeadshot" className="roundedImage center"
                                    style={{marginBottom: '80px', maxHeight: '300px'}}/>
        <p>
          <strong>Jeffrey Leonard</strong> is the Chief Financial Officer of IT Rooms Solutions and a founder of IT Room Solutions.
          <br/><br/>
          Jeffrey combines a deep understanding of finance with a background in tech and over a decade of experience working with small teams and large organizations. As CFO, he helps ensure ITRS continues to grow strategically and sustainably.
          <br/><br/>
          Jeffrey views finance as a tool to shape company direction, build longevity and nourish culture. His leadership approach is rooted in values of equity, sustainability and transparency. To this end, he leads the strategic investments required to create a people-centered work environment, drive better client solutions and accelerate growth.
        </p>
        <br/>
        <a href="https://www.linkedin.com/in/jeffrey-tyler-scott-leonard-22289623">
          <Image src={linkedInLogo} className="linkedInLogo"/>
        </a>
      </Container>
    </div>
  );
}
