import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";

import bikeImage from '../images/bike.jpg';
import bike2Image from '../images/bike2.jpg';
import carImage from '../images/car.jpg';
import elephant2Image from '../images/elephant2.jpg';
import redisLogo from '../images/RedisLogo.png';
import data3Image from '../images/data3.jpg';
import data4Image from '../images/data4.jpg';
import serverImage from '../images/server.jpg';
import server2Image from '../images/server2.jpg';
import server3Image from '../images/server3.jpg';


import { ReactComponent as CheckSvg } from '../images/done-white-18dp.svg';
import { Check2, Dot } from 'react-bootstrap-icons';




// This is the /Elephant page

export default function Elephant () {
  return (
    <div>
      {/* This is the first section with the Elephant header */}
      <Jumbotron className="blueGradientBackground" style={{padding: '1px'}}>
        <Container className="mainContainer" style={{marginTop: '90px', marginBottom: '90px'}}>
          <h1 className="titleHeader dropShadow" style={{marginBottom: '25px'}}>
            Elephant
          </h1>

          <h4 className="dropShadow veryLightGreyText" style={{textAlign: 'center'}}>
            Elephant is a fully-fledged, blazing-fast cache system. Extremely versatile, our .NET core API supports not only Redis but all other memory storage
          </h4>
        </Container>
      </Jumbotron>


      {/* This is the second section that contains the cards */}
      <Container className="mainContainer" style={{marginTop: '110px', marginBottom: '150px'}}>
        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* First Card: Comprehensive Caching */}
            <Card className="dropShadow wideCard">
              <Card.Img src={server3Image} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Comprehensive Caching</Card.Title>
                <Card.Text>
                  ITRS offers a fully-fledged, blazing-fast cache system. Extremely versatile, our .NET core API supports not only Redis but all other memory storage.
                  <br/><br/>
                  Elephant is versatile and lightning-fast. Our in-memory data store provides sub-millisecond response times to support the most demanding applications, offering high-speed performance and an end-to-end stack.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Second Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={carImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Turbo-Charged Performance</Card.Title>
                <Card.Text>
                  Elephant provides extreme performance under the most demanding conditions. In contrast to databases running from disk, Elephant offers performance orders of magnitude faster than magnetic or SSD disks. This lets you read and write data in less than a thousandth of a second.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Third Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={redisLogo} variant="top" className="cardImageCap"
                  style={{objectFit: 'contain', paddingTop: '30px', paddingLeft: '10%', paddingRight: '10%'}}/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">
                  Redis and Beyond
                </Card.Title>
                <Card.Text>
                  Elephant supports the open-source Redis data structure store. Redis has been widely adopted for use as a database, cache, message broker, and queue. Seamlessly migrate existing Redis workloads to ITRS for lightning-charged performance. Or use any other memory store.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Fourth Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={serverImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Managed Solution</Card.Title>
                <Card.Text>
                  We offer managed cache system solutions. That means you no longer need to provision hardware, execute software patching, or worry about backups and failure recovery. We take care of the maintenance so that you can concentrate on higher-value development tasks.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          {/* Fifth Card */}
          <Col lg={3}/>
          <Col style={{'display': 'flex'}}>
            <Card className="dropShadow wideCard">
              <Card.Img src={data3Image} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Performance Metrics</Card.Title>
                <Card.Text>
                  We provide up-to-date continuous system metrics so that you can monitor performance. Access detailed monitoring metrics on your nodes to respond to unforeseen issues immediately. But rest assured that our continuous monitoring takes care of all maintenance and ensures that your memory store is always up and running.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3}/>
        </Row>
      </Container>

      {/* Third Section: Key Features */}
      <Jumbotron className="lightGreyBackground" style={{padding: '1px'}}>
        <Container className="mainContainer headerChecklistContainer"
                      style={{marginTop: '150px', marginBottom: '150px'}}>
          <h3 className="titleHeader">
            Key Features:
          </h3>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            An in-memory JSON store
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Houses cached records
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Used to store temporary data
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Uses web APIs
          </h4>
          <Row>
            <Col lg={3}/>
            <Col>
              <Link to="/contact">
                <Button className="defaultButton">Contact Us</Button>
              </Link>
            </Col>
            <Col lg={3}/>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
}
