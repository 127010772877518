import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';
import tools from '../images/tools.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';
import f1Image from '../images/f1.jpg';
import lockImage from '../images/lock.jpg';
import conveyorImage from '../images/conveyorbelt.jpg';

import { Check2 } from 'react-bootstrap-icons';


// This is the /APGiant page



export default function APGiant () {

  return (
    <div>
      {/* This is the first section with the AP Giant header */}
      <Jumbotron className="blueGradientBackground" style={{padding: '1px'}}>
        <Container className="mainContainer" style={{marginTop: '90px', marginBottom: '90px'}}>
          <h1 className="titleHeader dropShadow" style={{marginBottom: '25px'}}>
            AP Giant
          </h1>

          <h4 className="dropShadow veryLightGreyText" style={{textAlign: 'center'}}>
            AP Giant is a powerful API manager that uses an event-driven microservices architecture to provide a robust set of tools to build and communicate between apps and services in a secure cloud-based environment
          </h4>
        </Container>
      </Jumbotron>

      {/* This is the second section that contains the cards */}
      <Container className="mainContainer" style={{marginTop: '110px', marginBottom: '150px'}}>
        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* First Card: Event-Driven Microservices Architecture */}
            <Card className="dropShadow wideCard">
              <Card.Img src={tools} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Event-Driven Microservices Architecture</Card.Title>
                <Card.Text>
                  AP Giant  uses an event-driven microservices architecture to provide a robust set of tools to build and communicate between apps and services in a secure cloud-based environment.
                  <br/><br/>
                  AP Giant takes care of many behind-the-scenes operations so that you can focus on developing and deploying microservices and apps with key value adds. Overall, our powerful manager platform lets you build, deploy, and manage APIs with security, performance, and scalability as top priorities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Second Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={lockImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Security</Card.Title>
                <Card.Text>
                  Your APIs need access permissions and protection. AP Giant provides authentication and authorization tools to govern who gets access to systems, resources, and information. This lets you select which data to expose and with whom to share it. AP Giant also monitors continuously for security threats and reports known vulnerabilities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Third Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={f1Image} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Performance</Card.Title>
                <Card.Text>
                  Demanding applications often rely on sub-millisecond response times from your APIs. AP Giant  tracks performance so that you can monitor and fully optimize for speed and efficiency. Log onto our developer portal for real-time performance data and analytics. AP Giant can be easily integrated with various monitoring tools Uptrends or Dynatrace.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Fourth Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={conveyorImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Scalable</Card.Title>
                <Card.Text>
                  AP Giant lets you start small and scale big. As your data grows, AP Giant allocates clusters and system resources automatically. It scales with your traffic and growth by dynamically adjusting read and write access across multiple availability zones.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* This is the third section: Key Features */}
      <Jumbotron className="lightGreyBackground" style={{padding: '1px'}}>
        <Container className="mainContainer headerChecklistContainer"
                      style={{marginTop: '150px', marginBottom: '150px'}}>
          <h3 className="titleHeader">
            Key Features:
          </h3>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Bridges the interactions between microservices
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Leverages Messaging Brokers such as RabbitMQ
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Adds reliability to an Event-Driven model
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Implements an Adapter Design Pattern
          </h4>
          <Row>
            <Col lg={3}/>
            {/*<Col>
              <Button onClick={() => {learnMoreAnchor.current.scrollIntoView({behavior: 'smooth'})}}
                    className="defaultButton whiteButton dropShadow">
                Learn More
              </Button>
            </Col>*/}
            <Col>
              <Link to="/contact">
                <Button className="defaultButton">Contact Us</Button>
              </Link>
            </Col>
            <Col lg={3}/>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
}
