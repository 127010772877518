import firebase from 'firebase';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJoXCz8rQTXuNwnnf7T-nQaoCAGfbZa3g",
  authDomain: "it-room-site.firebaseapp.com",
  databaseURL: "https://it-room-site.firebaseio.com",
  projectId: "it-room-site",
  storageBucket: "it-room-site.appspot.com",
  messagingSenderId: "38492482615",
  appId: "1:38492482615:web:e83590818cf64016ad63b2",
  measurementId: "G-74YZ8CY7D4"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;