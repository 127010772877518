import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";

import communityIcon from '../images/communityIcon.png';
import cutCostsIcon from '../images/cutCostsIcon.png';
import futureGearIcon from '../images/futureGearIcon.png';
import increaseRevIcon from '../images/increaseRevIcon.png';
import specialistIcon from '../images/specialistIcon.png';
import integrationIcon from '../images/integration.png';

import dellBoomiLogo from '../images/dellBoomiLogo.png';
import communityImage from '../images/community.jpg';
import conveyorImage from '../images/conveyorbelt.jpg';
import dataImage from '../images/data.jpg';


import { ChevronRight, ChevronLeft, Dot } from 'react-bootstrap-icons';



// These are the breakpoints for the carousel.
// The number of visible items depends on the width of the window/viewport
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 577 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 577, min: 0 },
    items: 1
  }
};


// This is the /DellBoomi page

export default function DellBoomi () {

  const integrationSpecialistsAnchor = useRef(null);
  const driveEfficiencyAnchor = useRef(null);
  const cutITCostsAnchor = useRef(null);
  const increaseRevenueAnchor = useRef(null);
  const boomiCommunityAnchor = useRef(null);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
     // These are the left and right arrows for the carousel
    return (
      <div className="carousel-button-group">
        <ChevronLeft id="carouselLeftArrow"  onClick={() => previous()} />
        <ChevronRight id="carouselRightArrow"  onClick={() => next()} />
      </div>
    );
  };


  return (
    <div>
      {/* This is the carousel at the top of the page */}
      <Jumbotron className="blueGradientBackground">
        <Container className="mainContainer">
          <h1 className="titleHeader dropShadow">
            Dell Boomi Integration Solutions
          </h1>
          <Carousel responsive={responsive} className="carousel"
                  arrows={false} renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                  infinite={true}>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={specialistIcon} style={{'margin': '-20px 0',
                    'max-height': '110px'}}/>
                <Card.Title>Integration Specialists</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We specialize in automating, integrating, and unifying apps and data so that businesses can accelerate processes and improve connectivity
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => integrationSpecialistsAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={futureGearIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Drive Efficiency</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We unlock efficiency-enhancing features of Dell Boomi to maximize productivity
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => driveEfficiencyAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={integrationIcon} style={{'margin': '-15px 0',
                    'max-height': '100px'}}/>
                <Card.Title>Full-scope Integrations</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  ITRS leverages Dell Boomi along with third-party analytics software
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => increaseRevenueAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body className="cardBodyWithBottomButton">
                <Card.Img src={communityIcon} style={{'margin': '-10px 0',
                    'max-height': '90px'}}/>
                <Card.Title>Boomi Community</Card.Title>
                <Card.Text style={{'text-align':'center'}}>
                  We’re active participants in the Dell Boomi community - this means we’re on the leading edge of innovation in the cloud integration space
                </Card.Text>
                <p className="cardBottomButton textLink center"
                    onClick={() => boomiCommunityAnchor.current.scrollIntoView({  behavior: 'smooth' })}>
                  Learn More
                </p>
              </Card.Body>
            </Card>
          </Carousel>
          <Link to="/contact">
            <Button className="defaultButton whiteButton dropShadow center">Contact Us</Button>
          </Link>
        </Container>
      </Jumbotron>

      {/* This is an anchor that the page will scroll to when "learn more" is clicked */}
      <div ref={integrationSpecialistsAnchor} />

      {/* This is the first section of the page under the carousel: Integration Specialists */}
      {/* the image is floated to the right so the section is offset to the left */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <Image src={dellBoomiLogo} className="float-md-right"/>
        <h4 className="sectionHeader">
          Integration Specialists
        </h4>
        <h5 className="sectionOneLiner">
          We specialize in automating, integrating, and unifying apps and data so that businesses can accelerate processes and improve connectivity
        </h5>
        <p>
          ITRS has an in-house staff of certified Dell Boomi developers. Businesses rely on a variety of often highly specialized apps that process and output data of all kinds. Software-as-a-Service apps may need to communicate with on-premises databases in ways the developers had never anticipated.
          <br/><br/>
          That’s where our work with Dell Boomi comes in. Boomi is an integration Platform as a Service (iPaaS) tool that allows us to engineer custom integration flows between various apps and data sources. We specialize in automating, integrating, and unifying apps and data so that businesses can accelerate processes and improve connectivity. Our Dell Boomi integrations break down data silos to drive business efficiency and productivity
          <br/><br/>
          Along with the rise in SaaS, demand for iPaaS solutions has increased significantly over the past decade. As businesses rely more and more on cloud services, integration has shifted from internal and on-premises processes to fully cloud-based solutions.. And, all along, ITRS has provided world-class cloud-based integration solutions that drive efficiency, cut IT costs, and increase revenue.
        </p>
      </Container>

      {/* This is another anchor */}
      <div ref={driveEfficiencyAnchor} />

      {/* This is the second section: Drive Efficiency */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          <Image src={conveyorImage} className="float-md-left roundedImage"/>
          <h4 className="sectionHeader">
            Drive Efficiency
          </h4>
          <h5 className="sectionOneLiner">
            We unlock efficiency-enhancing features of Dell Boomi to maximize productivity
          </h5>
          <p>
            ITRS helps businesses unlock such efficiency-enhancing features of Dell Boomi to boost productivity. ITRS leverages Boomi’s automation and AI capabilities like Boomi Suggest to tap into the knowledge base of the Boomi community and learn which data mappings are likely the most useful to you. The system then provides suggestions with confidence rankings, and users accept over 90% of the suggestions.
            <br/><br/>
            We also submit integrations to the automated regression testing feature, Boomi Assure. This feature tests your integrations with future Dell Boomi platform updates to ensure that your integrations remain solid and 100% dependable moving forward.
          </p>
        </Container>
      </Jumbotron>


      <div ref={increaseRevenueAnchor} />

      {/* Third Section */}
      <Container className="mainContainer offsetSection offsetLeftSection">
        <Image src={dataImage} className="float-md-right roundedImage"/>
        <h4 className="sectionHeader">
          Full-scope Integrations
        </h4>
        <h5 className="sectionOneLiner">
          ITRS leverages Dell Boomi along with third-party analytics software
        </h5>
        <p>
          For instance, the Master Data Hub facilitates BI analytics to display elegant data visualization and execute Natural Language Processing queries on data—for instance, “Show me all the customers that bought gloves on October 10th.”
          <br/><br/>
          Another useful integration we utilize is Boomi Flow, which allows the creation of web apps that give integrations and APIs a user experience or a “front end.” This lets us build a fully-fledged application using Dell Boomi with seamlessly integrated components.
        </p>
      </Container>

      <div ref={boomiCommunityAnchor} />

      {/* Fourth Section */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer offsetSection offsetRightSection">
          <Image src={communityImage} className="float-md-left roundedImage" style={{maxWidth: '350px'}}/>
          <h4 className="sectionHeader">
            Boomi Community
          </h4>
          <h5 className="sectionOneLiner">
            We’re active participants in the Dell Boomi community - this means we’re on the leading edge of innovation in the cloud integration space
          </h5>
          <p>
            We’re also key players in the Boomi community. As a group of highly trained Boomi experts, we’re active participants in the cloud integration community. This means that we’re on the leading edge of innovation in the cloud integration space.
            <br/><br/>
            For more  Dell Boomin information, tips, and solutions for IT professionals, <Link to="/Resources">visit our Resources page.</Link>
          </p>
        </Container>
      </Jumbotron>
    </div>
  );
}
