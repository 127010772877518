import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../App.css';

import firebase from "../../helperComponents/FirebaseConfig";

import linkedInLogo from '../../images/linkedInLogo.png';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

import justinHeadshot from '../../images/justinHeadshot.jpg';


// This is the /Leaders/JustinYandell page


export default function BioJustin () {
  return (
    <div>
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Justin Yandell
        </h1>
        <h4 className="sectionOneLiner" style={{textAlign: 'center', marginBottom: '80px'}}>
          Chief Operations Officer
        </h4>
        <Image src={justinHeadshot} id="schuylerHeadshot" className="roundedImage center"
                                    style={{marginBottom: '80px', maxHeight: '300px'}}/>

        <p>
          <strong>Justin Yandell</strong> is the Chief Operations Officer and a founder of IT Room Solutions.
          <br/><br/>
          Justin brings an extensive background in business administration and project management to his role as COO. He draws on both engineering knowledge and product management experience to ensure ITRS consistently delivers unparalleled experiences and enterprise solutions.
          <br/><br/>
          Justin creates and manages innovative and efficient teams through strategic leadership and oversight. Supporting the people-centred vision of ITRS, he constantly elevates his teammates’ performance through the analysis and improvement of work and communication processes as he adapts to change and growth.
          <br/><br/>
          Justin’s priority is to deliver a strong and consistent experience to clients. His proactive leadership approach and commitment to excellence drives the powerful workflows necessary in building ITRS’s world-class enterprise solutions.
        </p>
        <br/>
        <a href="https://www.linkedin.com/in/justinyandell">
          <Image src={linkedInLogo} className="linkedInLogo"/>
        </a>
      </Container>
    </div>
  );
}
