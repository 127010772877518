import React,
{ useState, useEffect, useRef, useCallback} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';

import ImageUploading from 'react-images-uploading';

import '../App.css';

// used for firestore, firebase's database solution
// and firebase storage, firebase's file storage solution
import firebase from "../helperComponents/FirebaseConfig";

import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';

import { Envelope, Telephone, GeoAlt } from 'react-bootstrap-icons';
import {useDropzone} from 'react-dropzone';

import CaptchaConfig from "../helperComponents/CaptchaConfig";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

// Code for pages that display individual job listings with details on that job

// helpful variables
const maxNumberOfCvFiles = 4;

export default function JobListing () {
  // get job id from params and use that id to get job data
  const jobIdFromParams = useParams().id;
  const currentJob = jobListingsDict[jobIdFromParams];


  // refs
  const captchaRef = useRef(null);
  const captchaCompleted = useRef(false);
  // this will be the url of the cv/resume after it is uploaded to server
  const uploadedCvs = useRef([]);

  // states
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [coverLetterValue, setCoverLetterValue] = useState('');
  const [cvValue, setCvValue] = useState([]);
  const [agreeValue, setAgreeValue] = useState(false);
  const [honeypotUsed, setHoneypotUsed] = useState(false);
  const [redirectState, setRedirectState] = useState({
      shouldRedirect: false,
      to: '',
    });

  // should we be showing an alert right now?
  const [alertState, setAlertState] = useState({
    visible: false,
    message: '',
    variant: 'danger',
  });

  const onDrop = useCallback((droppedFiles) => {
    // whenever a user drops or selects a file to be uploaded, update state
    setCvValue(droppedFiles);
  }, []);

  // setup for dropzone (where you can drop files for cv/resume)
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } =  useDropzone({
    // accept .pdf, .doc, .docx
    accept: ['.doc', '.docx', 'application/pdf', 'application/msword',
               'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    //multiple: false,
    onDrop,
  });





  // helper functions
  const setAlertTimeout = (delay = 2000) => {
    // start a countdown timer. after the timer expires, clear any visible alerts
    // delay is number of ms before alert is cleared
    setTimeout(() => {
      setAlertState({
        visible: false,
        message: '',
        variant: 'danger',
      });
    }, delay);

  }

  const validateAllForms = () => {
    // go through all input forms and make sure something valid has been entered for each
    // return true if all are valid, return false if at least one is invalid

    if (honeypotUsed) {
      // a bot triggered the honeypot, redirect to home page (away from field),
      // and don't submit data
      setRedirectState({
        shouldRedirect: true,
        to: '/'
      });

      setAlertState({
        visible: true,
        // this is untrue, message was not sent
        message: 'You message has been sent, thank you',
        variant: 'success',
      });

      setAlertTimeout();

      return false;
    }

    if (nameValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter your name',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (emailValue.length < 5 || !emailValue.includes('@') || !emailValue.includes('.')) {
      setAlertState({
        visible: true,
        message: 'Error: Invalid email address',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (phoneValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter your phone number',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (coverLetterValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please enter your cover letter',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (cvValue.length < 1) {
      setAlertState({
        visible: true,
        message: 'Error: Please upload your resume',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (cvValue.length > maxNumberOfCvFiles) {
      setAlertState({
        visible: true,
        message: 'Error: Please submit less than 5 files for your resume',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }
    if (!agreeValue) {
      setAlertState({
        visible: true,
        message: 'Error: Please agree to allow this site to store your data',
        variant: 'danger',
      });

      setAlertTimeout(4000);
      return false;
    }
    if (!captchaCompleted.current) {
      setAlertState({
        visible: true,
        message: 'Error: Please complete captcha',
        variant: 'danger',
      });

      setAlertTimeout();
      return false;
    }


    // after captcha is validated once, reset captcha
    captchaRef.current.reset();

    return true;
  }

  const uploadFile = (file, location) => {
    // upload "file" to "location" on firebase's file storage system
    // then return a promise that will complete after upload is done
    // also save file's name and download url to be uploaded to firestore

    // remove spaces and ?#[]* from file name
    const strippedFileName = file.name.replace(/\#|\s|\[|\]|\*|\?/g, '');

    // create a new promise that resolves when file has been uploaded
    return firebase.storage().ref(location+strippedFileName).put(file)
        .then((snapshot) => {
          // file uploaded successfuly,
          // now save file name and location in firebase storage
          uploadedCvs.current.push({
            name: strippedFileName,
            storageLocation: location+strippedFileName,
          });
        }).catch((error) => {
          // file upload failed
          console.log('One file upload failed', error.message);
        });
  }

  const sendButtonClicked = async () => {
    // this is what happens when someone clicks the "send" button
    const valid = validateAllForms();

    if (!valid)
      return;


    setAlertState({
      visible: true,
      message: 'Uploading please wait...',
      variant: 'info',
    });

    // current time in ms since unix epoch. To be used for ids
    const currTime = Date.now();

    // Upload to server
    uploadedCvs.current = [];
    const uploadPromises = [];
    // upload all files and make a list of promises that will resolve when uploads are completed
    for (let i = 0; i < cvValue.length; i++) {
      if (i >= maxNumberOfCvFiles) {
        // about to upload too many files
        break;
      }

      const thisFile = cvValue[i];
      uploadPromises.push(uploadFile(thisFile, 'jobApplications/ja_'+currTime+'/cv/'));
    }

    // wait for all promises to resolve
    await Promise.all(
      // Array of "Promises"
      uploadPromises
    )
    .then(() => {
      console.log('All success');
    })
    .catch((error) => {
      console.log('Some uploads failed: ', error.message);
      setAlertState({
        visible: true,
        message: 'Error: failed to upload resume. Please try again. '+error.message,
        variant: 'danger',
      });

      setAlertTimeout(3000);
      return;
    });



    const db = firebase.firestore();
    const firestoreJARef = db.collection('jobApplications');
    // the id of this entry will be ce_ followed by the current time in ms since unix epoch
    firestoreJARef.doc('ja_'+currTime).set({
      id: 'ja_'+currTime,
      hidden: false,
      timeMs: currTime,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),

      name: nameValue,
      email: emailValue,
      phone: phoneValue,
      coverLetter: coverLetterValue,
      cvs: uploadedCvs.current,

      jobId: currentJob.id,
      jobEmploymentType: currentJob.employmentType,
      jobHidden: currentJob.hidden,
      jobLocation: currentJob.location,
      jobPosition: currentJob.position,
      jobPostedTimeMs: currentJob.postedTimeMs,
      jobPostedTimestamp: currentJob.postedTimestamp,
      jobProduct: currentJob.product,
      jobReadableEmploymentType: currentJob.readableEmploymentType,
      jobReadableLocation: currentJob.readableLocation,
      jobReadablePosition: currentJob.readablePosition,
      jobReadableProduct: currentJob.readableProduct,
      jobTitle: currentJob.title,

    }).then(() => {
      setAlertState({
        visible: true,
        message: 'You application has been submitted, thank you',
        variant: 'success',
      });

      setAlertTimeout(3000);

      setNameValue('');
      setEmailValue('');
      setPhoneValue('');
      setCoverLetterValue('');
      setCvValue([]);
      setAgreeValue(false);


    }).catch((error) => {
      setAlertState({
        visible: true,
        message: 'Error: ' + error.message,
        variant: 'danger',
      });

      setAlertTimeout(3000);
    });

  }

  const onCaptchaChanged = (token) => {
    if (!token) {
      //console.log('null for captcha');
      captchaCompleted.current = false;
    }
    else {
      //console.log("Captcha sucess");
      // post to our server to verify captcha

      axios.post('https://us-central1-it-room-site.cloudfunctions.net/captcha',
          {'token': token})
        .then((res) => {
          const fromServer = res.data;

          if (fromServer['success']) {
            // captcha verified successfully
            captchaCompleted.current = true;
            console.log('captcha verified successfully');
          }
          else {
            // captcha not verified
            captchaCompleted.current = false;
            captchaRef.current.reset();

            setAlertState({
              visible: true,
              message: 'Captcha Failed',
              variant: 'danger',
            });

            setAlertTimeout();
            console.log('captcha not verified', fromServer);
          }
        });
    }
  }



  // Generate XML
  let display_text = '';
  let filesXml = [];
  try {
    cvValue.forEach((file, i) => {
      filesXml.push(<div>
        {file.path}
      </div>);
    });

  }
  catch(e) {
    console.log(e);
    console.log(cvValue);
    display_text += e.toString();
  }

  const generateAlert = () => {
    // check alertState and if necessary, build an alert based on it
    // if alertState.visible is not true, add 'opacity0' class
    return <Alert variant={alertState.variant}
                className={!alertState.visible? 'opacity0 displayNone': ''}>
      {alertState.message}
    </Alert>;
  }


  const currentAlert = generateAlert();


  if (redirectState.shouldRedirect)
    return <Redirect to={redirectState.to} />;


  if (!currentJob)
    // job listing not found
    return <h2 style={{fontWeight: 300, textAlign: 'center', marginTop: '100px'}}>
      Job Listing Not Found
    </h2>;

  // TODO: check to make sure job has all required info

  // this is what will be displayed on this page
  return (
    <div className="jobListingPage">
      {/* first section - main job listing text */}
      <Container className="mainContainer">
      { currentJob.xml }
      </Container>

      {/* Second section - input forms */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer">
          <Row>
            <Col lg={2}></Col>
            <Col>
              <h2 className="darkBlueText">Apply For This Position</h2>
              <br/><br/>
              <h5 className="formLabel">Full Name</h5>
              <Form.Control className="formInput"
                        placeholder="Enter Your Full Name"
                        value={nameValue}
                          // when a user types in this field,
                          // save what they type in the 'nameValue' state
                        onChange={(event) => {setNameValue(event.target.value)}}/>

              <h5 className="formLabel">Email</h5>
              <Form.Control className="formInput" type="email"
                        placeholder="Enter Your Email"
                        value={emailValue}
                        onChange={(event) => {setEmailValue(event.target.value)}}/>

              <h5 className="formLabel">Phone</h5>
              <Form.Control className="formInput" type="tel"
                        placeholder="Enter Your Phone Number"
                        value={phoneValue}
                        onChange={(event) => {setPhoneValue(event.target.value)}}/>

              <h5 className="formLabel">Cover Letter</h5>
              <Form.Control as="textarea" rows={16} className="formInput"
                          placeholder="Enter Your Cover Letter"
                          value={coverLetterValue}
                          onChange={(event) => {setCoverLetterValue(event.target.value)}}/>

              <h5 className="formLabel">Upload CV/Resume</h5>
              <section className="">
                <div {...getRootProps({
                    // add drag accept, drag reject, and drag active as css classes
                    className: 'dropzone '
                                  + (isDragActive? ' dragActive': '')
                                  + (isDragAccept? ' dragAccept' :'')
                                  + (isDragReject? ' dragReject': '')})}>
                  <input {...getInputProps()} />
                  <p>
                    Drag and Drop your File Here, or Click to Select Files.
                    <br/>(Accepted File Types: .pdf, .doc, .docx)
                  </p>
                </div>
                <br/>
                {// Don't show this section if there are no files to show
                }
                <aside style={{
                            marginBottom: '70px',
                            //  display: files.length > 0 || true? 'block': 'none'
                            }}>
                  <h6 className="greyText">{filesXml.length === 0? 'No': ''} Files Received</h6>
                  {filesXml}
                </aside>
              </section>


              {/* Honeypot */}
              <Form.Control type="text" name="enter_password" style={{display:'none'}}
                  tabindex="-1" autocomplete="off" onChange={() => setHoneypotUsed(true)} />


              <input id="allowDataCheck" className="form-check-input"
                      type="checkbox" checked={agreeValue}
                      onChange={(event) => setAgreeValue(event.target.checked)}/>
              <span className="form-check-label" onClick={setAgreeValue.bind(this, !agreeValue)}>
                I agree to allow this website to storage and handle my data
              </span>
              <div style={{height: '20px'}}/>
              <ReCAPTCHA className="captcha"
                ref={captchaRef}
                sitekey={CaptchaConfig.siteKey}
                onChange={onCaptchaChanged}
              />
              <Button className="defaultButton" onClick={sendButtonClicked}>Submit</Button>
            </Col>
            <Col lg={2}></Col>
          </Row>
        </Container>
      </Jumbotron>

      {currentAlert}
    </div>
  );
}

const dividerChar = <span style={{color: '#303030', fontWeight: 300}}>|</span>;

const jobListingsDict = {
  softwaredeveloperduckcreek:{
    id: 'softwaredeveloperduckcreek',
    employmentType: ['fulltime'],
    hidden: false,
    location: ['uticany'],
    position: ['softwaredeveloper'],
    postedTimeMs: 14,
    postedTimestamp: {time: 14},
    product: ['duckcreek'],
    readableEmploymentType: 'Full Time',
    readableLocation: 'Utica, NY',
    readablePosition: 'Software Developer',
    readableProduct: 'Duck Creek',
    title: 'Software Developer - Duck Creek',
    xml:
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Software Developer — Duck Creek
        </h1>
        <h4 className="sectionOneLiner blueText" style={{textAlign: 'center', marginBottom: '80px'}}>
          Full Time {dividerChar} Software Developer {dividerChar} Duck Creek {dividerChar} Utica, NY
        </h4>
        {/*}<Image src={typewriter} className="roundedImage w-100" style={{marginBottom: '80px'}}/>*/}
        <h4>
          IT Room Solutions is hiring talented software engineers.
        </h4>
        <h4>About Us</h4>
        <p>
          IT Room Solutions is a team of experienced technology professionals primarily serving the software, architecture, and technical management needs of the insurance and financial industries. We specialize in delivering complex customizations for the Duck Creek platform.
          <br/><br/>
          We know that you have options and that is why we believe in working to design a career that grows with you. We offer competitive salaries, a 401k savings plan, medical coverage, and long-term growth potential. We are the new kid on the block but our vision is long term.
        </p>
        <h4>About You</h4>
        <p>
          You have an established track record as a software developer, preferably with some background in the insurance or financial services industries. Experience with the Duck Creek platform is strongly preferred. Development experience with programming languages such as C#, .NET, JavaScript would be helpful.
          <br/><br/>
          We may be small, but our clients include some of the biggest names in the industry. We’re looking for someone who can adapt to various types of work environments and is comfortable with travel to client sites.
        </p>
        <h4>Let’s talk!</h4>
      </Container>
  },
  softwaredeveloperdellboomi:{
    id: 'softwaredeveloperdellboomi',
    employmentType: ['fulltime'],
    hidden: false,
    location: ['uticany'],
    position: ['softwaredeveloper'],
    postedTimeMs: 16,
    postedTimestamp: {time: 16},
    product: ['dellboomi'],
    readableEmploymentType: 'Full Time',
    readableLocation: 'Utica, NY',
    readablePosition: 'Software Developer',
    readableProduct: 'Dell Boomi',
    title: 'Software Developer - Dell Boomi',
    xml:
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Software Developer — Dell Boomi
        </h1>
        <h4 className="sectionOneLiner blueText" style={{textAlign: 'center', marginBottom: '80px'}}>
          Full Time {dividerChar} Software Developer {dividerChar} Dell Boomi {dividerChar} Utica, NY
        </h4>
        {/*}<Image src={typewriter} className="roundedImage w-100" style={{marginBottom: '80px'}}/>*/}
        <h4>
          IT Room Solutions is hiring talented software engineers.
        </h4>
        <h4>About Us</h4>
        <p>
          IT Room Solutions is a team of experienced technology professionals primarily serving the software, architecture, and technical management needs of the insurance and financial industries. We specialize in delivering complex customizations for the Duck Creek platform.
          <br/><br/>
          We know that you have options and that is why we believe in working to design a career that grows with you. We offer competitive salaries, a 401k savings plan, medical coverage, and long-term growth potential. We are the new kid on the block but our vision is long term.
        </p>
        <h4>About You</h4>
        <p>
          You have an established track record as a software developer, preferably with some background in the insurance or financial services industries. Experience with the Duck Creek platform is strongly preferred. Development experience with programming languages such as C#, .NET, JavaScript would be helpful.
          <br/><br/>
          We may be small, but our clients include some of the biggest names in the industry. We’re looking for someone who can adapt to various types of work environments and is comfortable with travel to client sites.
        </p>
        <h4>Let’s talk!</h4>
      </Container>
  },
  seniorsoftwaredeveloperduckcreek:{
    id: 'seniorsoftwaredeveloperduckcreek',
    employmentType: ['fulltime'],
    hidden: false,
    location: ['uticany', 'newyorkny'],
    position: ['seniorsoftwaredeveloper'],
    postedTimeMs: 18,
    postedTimestamp: {time: 18},
    product: ['dellboomi'],
    readableEmploymentType: 'Full Time',
    readableLocation: 'New York, NY, Utica, NY',
    readablePosition: 'Senior Software Developer',
    readableProduct: 'Duck Creek',
    title: 'Senior Software Developer - Duck Creek',
    xml:
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Senior Software Developer — Duck Creek
        </h1>
        <h4 className="sectionOneLiner blueText" style={{textAlign: 'center', marginBottom: '80px'}}>
          Full Time {dividerChar} Senior Software Developer {dividerChar} Duck Creek {dividerChar} New York, NY, Utica, NY
        </h4>
        {/*}<Image src={typewriter} className="roundedImage w-100" style={{marginBottom: '80px'}}/>*/}
        <h4>
          IT Room Solutions is hiring talented software engineers.
        </h4>
        <h4>About Us</h4>
        <p>
          IT Room Solutions is a team of experienced technology professionals primarily serving the software, architecture, and technical management needs of the insurance and financial industries. We specialize in delivering complex customizations for the Duck Creek platform.
          <br/><br/>
          We know that you have options and that is why we believe in working to design a career that grows with you. We offer competitive salaries, a 401k savings plan, medical coverage, and long-term growth potential. We are the new kid on the block but our vision is long term.
        </p>
        <h4>About You</h4>
        <p>
          You have an established track record as a software developer, preferably with some background in the insurance or financial services industries. Experience with the Duck Creek platform is strongly preferred. Development experience with programming languages such as C#, .NET, JavaScript would be helpful.
          <br/><br/>
          We may be small, but our clients include some of the biggest names in the industry. We’re looking for someone who can adapt to various types of work environments and is comfortable with travel to client sites.
        </p>
        <h4>Let’s talk!</h4>
      </Container>
  },
};
