import React, { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image'
import Card from 'react-bootstrap/Card'


import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import cafeImage from "../images/cafe.jpg";
import hallImage from "../images/hall.jpg";
import tuckerAndDerekHeadshot from "../images/tuckerAndDerekHeadshot.png";

// This site has pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

// this is the overall structure of the site
// urls -> functions


export default function Resources() {
  return (
    <div className="resourcesPage">
      <Jumbotron className="darkBackground imageBackground" style={{backgroundImage: `url(${cafeImage})`, padding: 1}}>
        <Container className="mainContainer">
          <div className="darkRadialBackground"
                style={{
                  padding: '30px 40px 70px 40px',
                  background: 'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.35) 62.12%, rgba(0, 0, 0, 0.00) 100%)'
                }}>
            <h1 className="titleHeader dropShadow" style={{marginBottom: '30px'}}>
              Resources
            </h1>
            <h3 className="subHeader dropShadow">
              Stay on top of industry trends, tips and information with our resources
            </h3>
          </div>
        </Container>
      </Jumbotron>

      <Container className="mainContainer" >
        <Row>
          <Col/>
          <Col style={{'display': 'flex'}} md={6}>
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton"
                  style={{marginBottom: '40px'}}>
                <Card.Img src={tuckerAndDerekHeadshot}  className="roundedImage cardImage" />
                <Card.Title style={{margkinBottom: '10px'}}>Getting Started with Dell Boomi</Card.Title>
                <Card.Text>
                  Meet Tucker & Derek. We’re Derek Cory and Tucker Mogren and we're certified Boomi Professional Integration Developers at IT Room Solutions. We’ll be publishing regular Dell Boomi tips and tutorials to help other IT professionals master the platform and get the most out of it for their clients...
                </Card.Text>
                <Link to="/Resources/MeetTuckerAndDerek">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col/>
          {/* link to purposeful tech page, now integrated into about us
          <Col style={{'display': 'flex'}} md={6}>
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton" style={{marginBottom: '40px'}}>
                <Card.Img src={hallImage} className="cardImage roundedImage"/>
                <Card.Title style={{markginBottom: '10px'}}>What Is Purposeful Technology?</Card.Title>
                <Card.Text>
                  Our mission at ITRS is to create technology with a purpose. In short, purposeful technology drives businesses forward by eliminating growth limitations.
                  This means that we always look at the big picture to see how our solutions help to accelerate development. It means that we solve your specific needs instead of a “one-size-fits-all” solution.
                  It means that we focus on people to deliver results...
                </Card.Text>
                <Link to="/Resources/WhatIsPurposefulTechnology">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>*/}
        </Row>
      </Container>
    </div>
  );
}
