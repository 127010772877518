import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';

import ImageUploading from 'react-images-uploading';

import Moment from 'react-moment';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";


// This is the /itrsadmin page


export default function Admin() {
  // refs
  const pwRef = useRef(null);

  // States
  const [signedInUser, setSignedInUser] = useState(null);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  // should we be showing an alert right now?
  const [alertState, setAlertState] = useState({
    visible: false,
    message: '',
    variant: 'danger',
  });

  // effects
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var displayName = user.displayName;
        var email = user.email;
        var emailVerified = user.emailVerified;
        var photoURL = user.photoURL;
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        var providerData = user.providerData;
        // ...
        setSignedInUser(user);
      } else {
        // User is signed out.
        // ...
        setSignedInUser(null);
        // clear any sensitive data
      }
    });
  }, []);


  // helper functions
  const loginPressed = () => {
    firebase.auth().signInWithEmailAndPassword(loginEmail, loginPassword)
      .then((user) => {

      })
      .catch((error) => {
        console.log(error);
        setAlertState({
          visible: true,
          message: 'Error: '+error.message,
          variant: 'danger',
        });

        setAlertTimeout();
      });
  }

  const logoutPressed = () => {
    firebase.auth().signOut();
  }

  const setAlertTimeout = (delay = 2000) => {
    // start a countdown timer. after the timer expires, clear any visible alerts
    // delay is number of ms before alert is cleared
    setTimeout(() => {
      setAlertState({
        visible: false,
        message: '',
        variant: 'danger',
      });
    }, delay);
  }


  // XML builders
  const generateAlert = () => {
    // check alertState and if necessary, build an alert based on it
    // if alertState.visible is not true, add 'opacity0' class
    return <Alert variant={alertState.variant}
                className={!alertState.visible? 'opacity0 displayNone': ''}>
      {alertState.message}
    </Alert>;
  }

  const currentAlert = generateAlert();


  return (
    <Container className="p-0 pt-5 m-0 adminPage" style={{textAlign: 'center'}}>
      <h3 className="titleHeader">
        Admin
        <Button className={"btn-danger " + (signedInUser? '': 'displayNone')}
                style={{marginLeft: '30px'}}
                  onClick={logoutPressed}>
          Logout
        </Button>
      </h3>

      <div className={signedInUser? 'opacity0 height0 displayNone': 'opacity1'}
            style={{marginLeft: '40px', marginRight: '40px',
                    transition: 'height 0.3s, opacity 0.3s'}}>
        {// log in screen. only visible if no user signed in
        }
        <Row>
          <Col md={2}/>
          <Col>
            <h3 className="darkBlueText" style={{marginBottom: '40px'}}>Login</h3>
            <h5 style={{textAlign: 'left'}}>Email</h5>
            <Form.Control className="formInput"
                      placeholder="Enter Your Email Address"
                      value={loginEmail}
                        // when a user types in this field,
                        // save what they type in the 'nameValue' state
                      onChange={(event) => {setLoginEmail(event.target.value)}}
                      // on enter pressed, focus on pw field
                      onKeyPress={(event) => {if (event.key === 'Enter') pwRef.current.focus()}}/>

            <h5 style={{textAlign: 'left'}}>Password</h5>
            <Form.Control className="formInput" type="password"
                      ref={pwRef}
                      placeholder="Enter Your Password"
                      value={loginPassword}
                        // when a user types in this field,
                        // save what they type in the 'nameValue' state
                      onChange={(event) => {setLoginPassword(event.target.value)}}
                      // on enter, submit
                      onKeyPress={(event) => {if (event.key === 'Enter') loginPressed()}}/>
            <Button className="defaultButton" onClick={loginPressed}>Submit</Button>
          </Col>
          <Col md={2} />
        </Row>
      </div>

      <Tabs defaultActiveKey="messages"
              className={signedInUser? 'opacity1': 'opacity0'}>
              { // tabs that show information on this screen
                // only visible if a user is signed in
              }
        <Tab eventKey="messages" title="Messages">
          {// if no user is signed in, hide this sensitive info
          }
          {signedInUser? <ContactEntries />: null}
        </Tab>
        <Tab eventKey="jobApplications" title="Job Applications">
          {signedInUser? <JobApplications />: null}
        </Tab>
      </Tabs>

      {currentAlert}
    </Container>
  );
}





const productIdsToNames = {
  duckcreek: 'Duck Creek Solutions',
  dellboomi: 'Dell Boomi Services',
  systemsolutions: 'Business System Solutions',
  apgiant: 'AP Giant',
  proof: 'Proof',
  elephant: 'Elephant',
  other: 'Other',
}
function ContactEntries() {
  // This is what will show in the Messages tab


  // states
  // what page are we on?
  // TODO: We should only be getting a certain number of messages at once
  const [page, setPage] = useState(0);
  const [entriesList, setEntriesList] = useState([]);
  const [entriesDict, setEntriesDict] = useState({});
  const [modalState, setModalState] = useState({
    visible: false,
    titleText: '',
    bodyXml: null,
  });

  // effects
  useEffect(() => {
    // get data from database, sort it by timeMs (time entered)
    // and store it in "entriesList" state
    const db = firebase.firestore();
    const listener = db.collection('contactEntries').onSnapshot(snap => {
      const tempEntryList = [];
      const tempEntryDict = {};
      snap.forEach(ceSnap => {
        const thisEntry = ceSnap.data();

        // check if anything vital (that will cause an error) is missing. If it is, continue
        if (!thisEntry.id || !thisEntry.timeMs || !thisEntry.name ||
              !thisEntry.subject || !thisEntry.message || !thisEntry.email ||
              !!thisEntry.hidden)
          return;

        tempEntryList.push(thisEntry);
        tempEntryDict[thisEntry.id] = thisEntry;
      });
      tempEntryList.sort((b, a) => {
        return a.timeMs - b.timeMs;
      });
      setEntriesList(tempEntryList);
      setEntriesDict(tempEntryDict);

    }, error => {
      console.log(error);
    });
  }, []);

  // helper functions
  const openModal = (entryId)  => {
    const entry = entriesDict[entryId];
    if (!entry) {
      console.log('Entry not found');
      return;
    }
    setModalState({
      visible: true,
      titleText: 'Message from ' + entry.name,
      //bodyText:
      bodyXml: <>
        <h5>{entry.subject}</h5>
        <p>{entry.message}</p>
        <div style={{height: '40px'}}/>
        <p><strong>Email:</strong> {entry.email}</p>
      </>
    });
  };

  const closeModal = () => {
    setModalState({
      visible: false,
      titleText: '',
      bodyXml: null,
    });
  }

  let modalXml = null;
  if (modalState.visible) {
    modalXml = <Modal show={modalState.visible} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>{modalState.titleText}</Modal.Title>
        <div className="modalX" onClick={closeModal}>
          &times;
        </div>
      </Modal.Header>

      <Modal.Body>
        <p>{modalState.bodyXml}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>;
  }


  // create main table to display
  const tableBody = [];
  for (let i = 0; i < entriesList.length; i++) {
    // build main table for this section

    const entry = entriesList[i];
    tableBody.push(<tr onClick={openModal.bind(this, entry.id)}
                          className="clickableRow">
      {// use Moment.js to convert unix timestamps to readable dates
      }
      <td>
        <Moment unix format="M/D/YYYY">{entry.timeMs/1000}</Moment><br/>
        <Moment unix format="LT">{entry.timeMs/1000}</Moment>
      </td>
      <td>{entry.name.slice(0, 100)}</td>
      {// if a name is available for this id, show the name, otherwise show the id
       //  the name is just the idea writen in a prettier, more readable way
      }
      <td>{productIdsToNames[entry.product] || entry.product}</td>
      <td>{entry.subject.slice(0, 100)}</td>
      <td>{entry.email.slice(0, 100)}</td>
    </tr>);
  }




  return (
    <div className="p-2 pt-5">
      <h4 className="darkBlueText">Enteries from "Contact Us" page</h4>
      <h5>Click on a row to see the message</h5>
      <br/>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Date Sent</th>
            <th>Name</th>
            <th>Product</th>
            <th>Subject</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </Table>
      {modalXml}
    </div>
  );
}





function JobApplications() {
  // This is what will show in the Job Applications tab


  // states
  // what page are we on?
  // TODO: We should only be getting a certain number of messages at once
  const [page, setPage] = useState(0);
  const [appList, setAppList] = useState([]);
  const [appDict, setAppDict] = useState({});
  const [modalState, setModalState] = useState({
    visible: false,
    titleText: '',
    bodyXml: null,
  });
  const storage = firebase.storage();

  // effects
  useEffect(() => {
    // get data from database, sort it by timeMs (time entered)
    // and store it in "entriesList" state
    const db = firebase.firestore();
    const listener = db.collection('jobApplications').onSnapshot(snap => {
      const tempAppList = [];
      const tempAppDict = {};
      snap.forEach(jaSnap => {
        const thisApp = jaSnap.data();

        // check if anything vital (that will cause an error) is missing. If it is, continue
        if (!thisApp.id || !thisApp.timeMs || !!thisApp.hidden ||
              !thisApp.cvs || !thisApp.jobEmploymentType ||
              !thisApp.jobLocation || !thisApp.jobProduct ||
              !thisApp.jobPosition)
          return;

        tempAppList.push(thisApp);
        tempAppDict[thisApp.id] = thisApp;
      });
      tempAppList.sort((b, a) => {
        return a.timeMs - b.timeMs;
      });
      setAppList(tempAppList);
      setAppDict(tempAppDict);
      console.log(tempAppList);

      // get download urls for all cvs
      const promiseList = [];
      tempAppList.forEach((jobApp, jobAppIndex) => {
        const cvList = jobApp.cvs;

        promiseList.push(cvList.map((thisCv, cvIndex) => {
          return storage.ref(thisCv.storageLocation).getDownloadURL()
            .then(storageUrl => {
              tempAppList[jobAppIndex].cvs[cvIndex].url = storageUrl;
              tempAppDict[jobApp.id].cvs[cvIndex].url = storageUrl;
            });
        }));
      });

      Promise.all(
        // Array of "Promises"
        promiseList
      )
      .then(() => {
        console.log('All success');
        setAppList(tempAppList);
        setAppDict(tempAppDict);
      })
      .catch((error) => {
        console.log('Getting urls failed: ', error.message);
        /*setAlertState({
          visible: true,
          message: 'Error: failed to upload resume. Please try again. '+error.message,
          variant: 'danger',
        });

        setAlertTimeout(3000);*/
        return;
      });

    }, error => {
      console.log(error);
    });
  }, []);

  // helper functions
  const openModal = (appId)  => {
    const jobApp = appDict[appId];
    if (!jobApp) {
      console.log('Entry not found');
      return;
    }
    console.log('job app', jobApp);
    setModalState({
      visible: true,
      titleText: 'Message from ' + jobApp.name,
      //bodyText:
      bodyXml: <>
        <h5>Application for {jobApp.jobTitle}</h5>
        <h5 className="greyText">{jobApp.jobReadableLocation}</h5>
        <p>{jobApp.coverLetter}</p>
        <br/><br/>
        <h5>Resume:</h5>
        {jobApp.cvs.map(cv => <>
          <a href={cv.url}>{cv.name}</a>
          <br/>
        </>)}
        <div style={{height: '80px'}}/>
        <p><strong>Email:</strong> {jobApp.email}</p>
        <p><strong>Phone:</strong> {jobApp.phone}</p>
      </>
    });
  };

  const closeModal = () => {
    setModalState({
      visible: false,
      titleText: '',
      bodyXml: null,
    });
  }

  let modalXml = null;
  if (modalState.visible) {
    modalXml = <Modal show={modalState.visible} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>{modalState.titleText}</Modal.Title>
        <div className="modalX" onClick={closeModal}>
          &times;
        </div>
      </Modal.Header>

      <Modal.Body>
        <p>{modalState.bodyXml}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>;
  }


  // create main table to display
  const tableBody = [];
  for (let i = 0; i < appList.length; i++) {
    // build main table for this section

    const jobApp = appList[i];
    tableBody.push(<tr onClick={openModal.bind(this, jobApp.id)}
                          className="clickableRow">
      {// use Moment.js to convert unix timestamps to readable dates
      }
      <td>
        <Moment unix format="M/D/YYYY">{jobApp.timeMs/1000}</Moment><br/>
        <Moment unix format="LT">{jobApp.timeMs/1000}</Moment>
      </td>
      <td>{jobApp.name.slice(0, 100)}</td>
      {// if a name is available for this id, show the name, otherwise show the id
       //  the name is just the idea writen in a prettier, more readable way
      }
      <td>{jobApp.jobTitle}</td>
      <td>{jobApp.email.slice(0, 100)}</td>
      <td>{jobApp.phone.slice(0, 100)}</td>
    </tr>);
  }




  return (
    <div className="p-2 pt-5">
      <h4 className="darkBlueText">Job Applications from "Careers" page</h4>
      <h5>Click on a row to see the Cover Letter and Download the Resume</h5>
      <br/>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Date Sent</th>
            <th>Name</th>
            <th>Job</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </Table>
      {modalXml}
    </div>
  );
}
