import React, { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';
import hallImage from '../images/hall.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';
import elephantIcon from '../images/elephant.png';

import itrsLogo from '../images/logo.png';

import capgeminiLogo from '../images/companyLogos/capgeminiLogo.png';
import chubbLogo2 from '../images/companyLogos/chubbLogo2.png';
import duckCreekLogo3 from '../images/companyLogos/duckcreekLogo3.png';
import hartfordLogo from '../images/companyLogos/hartfordLogo.png';
import kwLogo from '../images/companyLogos/kwLogo.png';
import siriLogo from '../images/companyLogos/siriLogo.png';

// This is the home page

export default function Home () {
  return (
    <div>
      {/* First section. "Tell us your business problems" */}
      <Jumbotron className="darkBackground imageBackground" style={{backgroundImage: `url(${hallImage})`}}>
        <Container className="mainContainer" style={{marginTop: '30px', marginBottom: '70px'}}>
          <div className="darkRadialBackground" style={{paddingTop: '80px'}}>
            <h1 className="titleHeader dropShadow" style={{marginBottom: '50px'}}>
              We build enterprise solutions that power business potential
            </h1>
            <Link to="/Contact">
            <Button className="defaultButton center dropShadow">Contact Us</Button>
            </Link>
          </div>
        </Container>
      </Jumbotron>

      {/* Second section, 3 cards with dark background */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer" style={{marginTop: '90px', marginBottom: '80px'}}>
          {/*}<h1 className="titleHeader" style={{marginBottom: '70px'}}>
            Consulting Services
          </h1>*/}
          <Row>
            <Col style={{'display': 'flex'}} lg={4} md={6}>
              {/* First Card */}
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={itrsLogo} style={{
                      'max-height': '82px'
                    }}/>
                  <Card.Title>Enterprise Solutions</Card.Title>
                  <Card.Text>
                    We specialize in creating powerful, user-centric enterprise applications in the areas of insurtech, fintech, and business services.
                  </Card.Text>
                  <Link to="/Solutions">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col style={{'display': 'flex'}} lg={4} md={6}>
              {/* Second Card */}
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={duckCreekLogo} style={{'margin': '-40px 0',
                      'max-height': '150px'}}/>
                  <Card.Title>Duck Creek Insurtech Solutions</Card.Title>
                  <Card.Text>
                    We supply technology strategy and end-to-end Duck Creek implementations to manage and transform your P&C insurance business to meet the demands of today.
                  </Card.Text>
                  <Link to="DuckCreek">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col style={{'display': 'flex'}} lg={4}>
              {/* Third Card */}
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={dellBoomiLogo} style={{
                      'max-height': '82px'
                    }}/>
                  <Card.Title>Dell Boomi Integration Services</Card.Title>
                  <Card.Text>
                    We leverage Dell Boomi along with third-party softwares to maximize what can be achieved.
                  </Card.Text>
                  <Link to="/DellBoomi">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      {/* Third section, Purposeful Tech */}
      <Container className="mainContainer" style={{marginTop: '170px', marginBottom: '130px'}}>
        <Row>
          <Col md={8} style={{
                        'align-items': 'center',
                        'display': 'flex',
                        'flex-direction': 'column',
                        'justify-content': 'center',
                      }}>
            <h1 className="titleHeader">
              We create technology that accelerates business growth
            </h1>
            <Link to="/About">
              <a className="textLink">
                Learn about our approach
              </a>
            </Link>
          </Col>
          <Col md={{span: 4, order: 'last'}} xs={{order: 'first'}}
                style={{marginBottom: '50px'}}>
            <Image src={laptop} className="mw-100 roundedImage"/>
          </Col>
        </Row>
      </Container>

      {/* Fourth section, 3 cards with dark background */}
      <Jumbotron className="lightGreyBackground" style={{borderRadius: 0}}>
        <Container className="mainContainer" style={{marginTop: '120px', marginBottom: '120px'}}>
          {/*}<h1 className="titleHeader" style={{marginBottom: '70px'}}>
            Products
          </h1>*/}
          <Row>
            <Col style={{'display': 'flex'}} lg={4} md={6}>
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={gears} style={{maxHeight: '98px'}}/>
                  <Card.Title>AP Giant</Card.Title>
                  <Card.Text>
                    AP Giant is a powerful API manager that uses an event-driven microservices architecture to provide a robust set of tools to build and communicate between apps and services in a secure cloud-based environment
                  </Card.Text>
                  <Link to="/APGiant">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col style={{'display': 'flex'}} lg={4} md={6}>
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={checklist}/>
                  <Card.Title>Proof</Card.Title>
                  <Card.Text>
                    Proof is an automated testing utility with a variety of pre-built, ready to use templates for collecting requirements and generating test cases
                  </Card.Text>
                  <Link to="/Proof">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col style={{'display': 'flex'}} lg={4}>
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  <Card.Img src={elephantIcon} style={{maxHeight: '98px'}}/>
                  <Card.Title>Elephant</Card.Title>
                  <Card.Text>
                    Elephant is a fully-fledged, blazing-fast cache system. Extremely versatile, our .NET core API supports not only Redis but all other memory storage
                  </Card.Text>
                  <Link to="/Elephant">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
			<Col style={{'display': 'flex'}} lg={4}>
              <Card className="dropShadow">
                <Card.Body className="cardBodyWithBottomButton">
                  
                  <Card.Title>DCXAS™</Card.Title>
                  <Card.Text>
                    DCXAS™ is a highly customizable XML Augmentation Subsystem for On-Prem DuckCreek that integrates with the Duck Creek platform providing a shadow database for back-end data developers to have accelerated and simplified access to the XML-encoded information.  
                  </Card.Text>
                  <Link to="/DCXAS">
                    <Button className="defaultButton cardBottomButton center">Learn More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      {/* Fifth Section: Learn More About Our Process */}
      <Container className="mainContainer">
        <Row>
          <Col>
            <h1 className="titleHeader" style={{marginBottom: '30px'}}>
              Trusted by powerful partners from start up to enterprise
            </h1>
            <div className="companyLogos" style={{textAlign: 'center'}}>
              <Image src={capgeminiLogo} style={{width: '300px'}}/>
              <Image src={chubbLogo2} style={{width: '300px'}}/>
              <Image src={hartfordLogo} style={{width: '200px'}}/>
              <Image src={kwLogo} style={{width: '100px'}}/>
              <Image src={siriLogo} style={{ width: '300px'}}/>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Last Section: Learn More About Our Process */}
      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer">
          <Row>
            <Col>
              <h1 className="titleHeader">
                Stay on top of industry trends, tips and information with our resources
              </h1>
              <Link to="/Resources">
                <Button className="defaultButton center">Resources</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>
  );
}
