import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';
import tools from '../images/tools.jpg';

import checklist from '../images/checklist.png';
import db from '../images/db.png';
import gears from '../images/gears.png';

import { ReactComponent as CheckSvg } from '../images/done-white-18dp.svg';
import { Check2, Dot } from 'react-bootstrap-icons';

import dataImage from '../images/data.jpg';
import data2Image from '../images/data2.jpg';
import serverImage from '../images/server.jpg';
import meetingImage from '../images/meeting.jpg';
import data4Image from '../images/data4.jpg';


// This is the /Proof page


export default function Home () {
  return (
    <div>
      {/* This is the first section with the Proof header */}
      <Jumbotron className="blueGradientBackground" style={{padding: '1px'}}>
        <Container className="mainContainer" style={{marginTop: '90px', marginBottom: '90px'}}>
          <h1 className="titleHeader dropShadow" style={{marginBottom: '25px'}}>
            Proof
          </h1>

          <h4 className="dropShadow veryLightGreyText" style={{textAlign: 'center'}}>
            Proof is an automated testing utility with a variety of pre-built, ready to use templates for collecting requirements and generating test cases
          </h4>
        </Container>
      </Jumbotron>


      {/* This is the second section that contains the cards */}
      <Container className="mainContainer" style={{marginTop: '110px', marginBottom: '150px'}}>
        <Row>
          <Col style={{'display': 'flex'}} md={6}>

            {/* First Card: Automated Continuous Testing */}
            <Card className="dropShadow wideCard">
              <Card.Img src={data4Image} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Automated Continuous Testing</Card.Title>
                <Card.Text>
                  ITRS offers an automated continuous testing environment. Proof works for a range of use cases.
                  <br/><br/>
                  <Dot/>  	Web and mobile applications <br/>
                  <Dot/>  	Cloud and enterprise <br/>
                  <Dot/>  	Scripts and libraries <br/>
                  <Dot/>  	Integrations <br/>
                  <br/>
                  In each case, you get fast, in-sprint testing to improve reliability and productivity and test better.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Second Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={data2Image} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Fast Automatic Testing</Card.Title>
                <Card.Text>
                  Proof’s interface is simple and intuitive. It saves time by simplifying testing with smart batch processing. This lets you run thousands of tests in just minutes.
                  <br/><br/>
                  Automate tests without writing a single line of code. This means that non-technical staff and other stakeholders can also schedule tests. More eyes on the success rate of your software let you accelerate productivity and eliminate bugs and crashes for good.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Third Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={meetingImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Test During Sprints</Card.Title>
                <Card.Text>
                  We support Shift Left. Proof lets you automate in-sprint testing through complete version control integration. If you subscribe to the motto “test early and often,” our automatic tester is for you.
                  <br/><br/>
                  With rapid feedback and CI/CD integration, Proof lets you start automation testing from the first line of code and continue until you’re ready to ship.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Fourth Card */}
            <Card className="dropShadow wideCard">
              <Card.Img src={serverImage} variant="top" className="cardImageCap"/>
              <Card.Body className="">
                <Card.Title className="darkBlueText">Optimize and Test Better</Card.Title>
                <Card.Text>
                  Remove redundancies and test better. Proof records modules and processes that have already been tested. This lets you focus on new or altered code to accelerate testing overall and increase your test coverage while reducing cases.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* This is the third section: Key Features */}
      <Jumbotron className="lightGreyBackground" style={{padding: '1px'}}>
        <Container className="mainContainer headerChecklistContainer"
                      style={{marginTop: '150px', marginBottom: '150px'}}>
          <h3 className="titleHeader">
            Key Features:
          </h3>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Schedule automated tests for front end screens, system generated pdf documents, and emails
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Standardize requirement collection with pre-built templates
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Self healing tests cases that automatically update when requirements change
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Creates new tickets and updates existing ones in project management software after test runs
          </h4>

          <div style={{height: '100px'}}/>
          <h3 >Available Features Built for Insurance:</h3>
          <div style={{height: '50px'}}/>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Automate testing Premium and Fees rating calculation testing
          </h4>
          <h4 className="checklistEntry">
            <Check2 className="check" />
            Project how potential rating changes affect an entire book of business with included calculator
          </h4>

          <div style={{height: '30px'}}/>
          <Row>
            <Col lg={3}/>
            <Col>
              <Link to="/contact">
                <Button className="defaultButton">Contact Us</Button>
              </Link>
            </Col>
            <Col lg={3}/>
          </Row>
        </Container>
      </Jumbotron>

    </div>
  );
}
