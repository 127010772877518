import React, { useState, useEffect, useRef } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import '../../App.css';

import firebase from "../../helperComponents/FirebaseConfig";

import typewriter from '../../images/typewriter.jpg';
import linkedInLogo from '../../images/linkedInLogo.png';


import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';

import schuylerHeadshot from '../../images/schuylerHeadshot.jpg';


// This is the /Leaders/SchuylerCornwell page


export default function BioSchuyler () {
  return (
    <div>
      <Container className="mainContainer" style={{marginTop: '100px'}}>
        <h1 className="sectionHeader"
              style={{textAlign: 'center', fontWeight: '300', marginBottom: '30px' }}>
          Schuyler Cornwell
        </h1>
        <h4 className="sectionOneLiner" style={{textAlign: 'center', marginBottom: '80px'}}>
          Chief Executive Officer
        </h4>
        <Image src={schuylerHeadshot} id="schuylerHeadshot" className="roundedImage center"
                                    style={{marginBottom: '80px', height: '280px'}}/>

        <p>
          <strong>Schuyler Cornwell</strong> is the Chief Executive Officer and a founder of IT Room Solutions.
          <br/><br/>
          Schuyler brings extensive experience as a software engineer and years of building client relationships to his role as CEO. As a thought leader in the enterprise software development space, Schuyler builds strategic relationships and partnerships that facilitate long-term growth. Placing relationships at the center of his work, Schuyler believes that people drive potential and transparency enables success.
          <br/><br/>
          Schuyler is a visionary executive with a background as an engineer. Years of intense experience in enterprise software development provide the foundations of his strategic vision and leadership. Even at an early age, Schuyler discovered both his passion for technology and his belief in the power of relationships and collaboration. As a student, Schuyler studied Electrical Engineering and Computer Engineering as he developed an insatiable curiosity for tech and a talent for coding. Years later, he opened the first ITRS office in Utica, New York with his brother, Jarred, with a mission built around people-centred, purposeful technology.
          <br/><br/>
          Schuyler embraces change and drives business growth through leadership. As CEO, Schuyler leverages foresight and vision to realize ITRS’s mission of powering business potential through purposeful technology. As a result of his commitment to strategic growth, Schuyler has led ITRS to become competitive with some of the largest software contractors in the world. In less than six months, he helped ITRS become a major client’s preferred alternative to the second highest grossing firm in the industry.
        </p>
        <br/>
        <a href="https://www.linkedin.com/in/schuyler-cornwell">
          <Image src={linkedInLogo} className="linkedInLogo"/>
        </a>
      </Container>
    </div>
  );
}
