import React, { useState, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import ImageUploading from 'react-images-uploading';

import '../App.css';

import firebase from "../helperComponents/FirebaseConfig";
import duckCreekLogo from '../images/duckCreekLogo.png';
import dellBoomiLogo from '../images/dellBoomiLogo.png';
import laptop from '../images/laptop.jpg';

import jarredHeadshot from '../images/jarredHeadshot.png';
import schuylerHeadshot from '../images/schuylerHeadshot.jpg';
import jeffreyHeadshot from '../images/jeffreyHeadshot.png';
import justinHeadshot from '../images/justinHeadshot.jpg';

import db from '../images/db.png';
import gears from '../images/gears.png';

import blackAndWhiteCity from '../images/blackAndWhiteCity.jpg';



// This is the /About page

export default function About () {
  return (
    <div className="aboutUsPage">
      {/* First section: About Us header */}
      <Jumbotron className="darkBackground imageBackground" style={{backgroundImage: `url(${blackAndWhiteCity})`}}>
        <Container className="mainContainer" style={{marginTop: '40px', marginBottom: '80px'}}>
          <div className="darkRadialBackground">
            <h1 className="titleHeader dropShadow" style={{marginBottom: '30px'}}>
              About Us
            </h1>
            <h3 className="subHeader dropShadow">
              We power business potential through purposeful technology
            </h3>
          </div>
          <Link to="/Contact">
            <Button className="defaultButton whiteButton center" style={{marginTop: '20px'}}>
              Contact Us
            </Button>
          </Link>
        </Container>
      </Jumbotron>

      {/* Second Section */}
      <Container className="mainContainer" style={{marginTop: '140px', marginBottom: '140px'}}>
        <p>
          Before we created IT Room Solutions, we were a team of passionate engineers witnessing an industry commission and create technology without always understanding why. How will this technology better the client’s business operations? How will it serve the client’s growth? What is the true long-term value? When these questions go unanswered and the technology being built doesn’t have a clear purpose and clients don’t receive the best possible solutions for their unique business needs. The result is short-term outcomes and missed long-term opportunities.
          <br/><br/>
          We knew there was a better way to leverage technology to improve business outcomes. We also knew there was a better way to build a tech company. And so, we created IT Room Solutions with the promise of doing better. We even coined the term “purposeful technology” and defined it simply as technology built to accelerate business growth. We emphasize it in our company mission: to power business potential through purposeful technology.
          <br/><br/>
          Our commitment to only building purposeful technology means we know the “why” behind every client recommendation. It means we assess our clients business challenges, discover unseen opportunities and work to actualize their long-term goals. And ultimately, it means our technology solutions power business potential.
          <br/><br/>
          Our values help us make good on our mission.
        </p>
      </Container>

      <Jumbotron className="lightGreyBackground">
        <Container className="mainContainer" style={{marginTop: '80px', marginBottom: '80px'}}>
          <h1 className="darkBlueText" style={{textAlign: 'center', marginBottom: '80px'}}>
            We Believe
          </h1>
          <Row>
            <Col style={{'display': 'flex'}} lg={4} md={6}>
              <Card className="dropShadow" style={{width: '100%'}}>
                <Card.Body className="">
                  {/*}<Card.Img src={duckCreekLogo} style={{'margin': '-40px 0',
                      'max-height': '150px'}}/>*/}
                  <Card.Title>People Drive Potential</Card.Title>
                  <Card.Text>
                    At ITRS, we believe people drive potential. In fact, that belief is what propelled us to create ITRS. Our company, products and services all start with teams of the brightest engineers and technologists.
                    <br/><br/>
                    Beyond sourcing top talent, we invest heavily in providing ongoing professional training, upskilling and learning opportunities for all of our people. This ensures our people remain industry leaders and provide our clients with the best possible outcomes.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col style={{'display': 'flex'}} lg={4} md={6}>
              <Card className="dropShadow" style={{width: '100%'}}>
                <Card.Body className="">
                  {/*}<Card.Img src={duckCreekLogo} style={{'margin': '-40px 0',
                      'max-height': '150px'}}/>*/}
                  <Card.Title>Transparency Enables Success</Card.Title>
                  <Card.Text>
                    ITRS believes that lasting relationships and successful partnerships begin with transparency. We aspire to be true partners to our clients by ensuring we’re upfront and collaborative through the processes, challenges and opportunities of every project.
                    <br/><br/>
                    By working in tandem with our clients, we create an allied partnership that allows us to overcome any business challenge and capitalize on any business opportunity efficiently and successfully.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col style={{'display': 'flex'}} lg={4}>
              <Card className="dropShadow" style={{width: '100%'}}>
                <Card.Body className="">
                  <Card.Title>Sustainability Brings Growth</Card.Title>
                  {/*}<Card.Img src={duckCreekLogo} style={{'margin': '-40px 0',
                      'max-height': '150px'}}/>*/}
                  <Card.Text>
                    We’re a company that builds for the long-haul and we believe all solutions should be built with longevity in mind. We not only deliver on immediate pain points but implement solutions that continue to reap powerful ROI rewards for our clients. That means we engineer technology that has the flexibility, durability and foresight to not only endure but facilitate the changes and expansions our clients undergo.
                    <br/><br/>
                    Our commitment to building only sustainable technology solutions ensures our clients will only be building towards continued growth rather than wasting time and resources rebuilding foundational elements.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Jumbotron>

      {/* Our Leaders section */}
      <Container className="mainContainer" style={{marginTop: '140px'}}>
        <h1 className="darkBlueText" style={{textAlign: 'center', marginBottom: '80px'}}>
          Our Leaders
        </h1>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            {/* Schuyler Cornwell's Card */}
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton" style={{marginBottom: '40px'}}>
                <Card.Img src={schuylerHeadshot} className="roundedImage" style={{maxHeight: '200px'}}/>
                <Card.Title style={{marginBottom: '10px'}}>Schuyler Cornwell</Card.Title>
                <Card.Title className="greyText" style={{marginTop: '0px', marginBottom: '40px'}}>
                  Chief Executive Officer
                </Card.Title>
                <Card.Text>
                  Schuyler brings extensive experience as a software engineer and years of building client relationships to his role as CEO. As a thought leader in the enterprise software development space, Schuyler builds strategic relationships and partnerships that facilitate long-term growth. Placing relationships at the center of his work, Schuyler believes that people drive potential and transparency enables success....
                </Card.Text>
                <Link to="/Leaders/SchuylerCornwell">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
          {/* Jarred Cornwell's Card */}
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton" style={{marginBottom: '40px'}}>
                <Card.Img src={jarredHeadshot} className="roundedImage" style={{maxHeight: '200px'}}/>
                <Card.Title style={{marginBottom: '10px'}}>Jarred Cornwell</Card.Title>
                <Card.Title className="greyText" style={{marginTop: '0px', marginBottom: '40px'}}>
                  Chief Technology Officer
                </Card.Title>
                <Card.Text>
                  Jarred Cornwell is the Chief Technology Officer and one of the founders of IT Room Solutions. He was selected and approved by the leadership team for the role. Cornwell has a significant background in enterprise architecture, managing IT teams...
                </Card.Text>
                <Link to="/Leaders/JarredCornwell">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col style={{'display': 'flex'}} md={6}>
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton" style={{marginBottom: '40px'}}>
                <Card.Img src={jeffreyHeadshot} className="roundedImage" style={{maxHeight: '200px'}}/>
                <Card.Title style={{marginBottom: '10px'}}>Jeffrey Leonard</Card.Title>
                <Card.Title className="greyText" style={{marginTop: '0px', marginBottom: '40px'}}>
                  Chief Financial Officer
                </Card.Title>
                <Card.Text>
                  Jeffrey combines a deep understanding of finance with a background in tech and over a decade of experience working with small teams and large organizations. As CFO, he helps ensure ITRS continues to grow strategically and sustainably...
                </Card.Text>
                <Link to="/Leaders/JeffreyLeonard">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col style={{'display': 'flex'}} md={6}>
            <Card className="dropShadow wideCard" style={{width: '100%'}}>
              <Card.Body className="cardBodyWithBottomButton" style={{marginBottom: '40px'}}>
                <Card.Img src={justinHeadshot} className="roundedImage" style={{maxHeight: '200px'}}/>
                <Card.Title style={{marginBottom: '10px'}}>Justin Yandell</Card.Title>
                <Card.Title className="greyText" style={{marginTop: '0px', marginBottom: '40px'}}>
                  Chief Operations Officer
                </Card.Title>
                <Card.Text>
                  Justin brings an extensive background in business administration and project management to his role as COO. He draws on both engineering knowledge and product management experience to ensure ITRS consistently delivers unparalleled experiences and enterprise solutions...
                </Card.Text>
                <Link to="/Leaders/JustinYandell">
                  <p className="cardBottomButton textLink center">
                    Keep Reading
                  </p>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  );
}
